import axios from 'axios';
import { camelizeKeys } from 'humps';
import AuthService from '../Auth';
import { LocalStorage } from '../../enums/LocalStorage';

const config = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

config.interceptors.request.use(function (config) {

  const authData = localStorage.getItem(LocalStorage.Auth);
  if (authData) {
      const token = JSON.parse(authData)['accessToken'];
      config.headers['Authorization'] =  'Bearer ' + token;
      AuthService.checkAccessToken();
    }

    return config;
});

config.interceptors.response.use(function (response) {
    if (response.data) {
        response.data = camelizeKeys(response.data);
    }
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

export default config;