import React, { useState, useEffect } from "react";
import styles from './Header.module.scss'
import { Link, useHistory, useLocation } from "react-router-dom";
import { URLRoutes } from "../../enums/Routes";
import { useTranslation } from 'react-i18next';
import '../../i18n/config';
import AuthService from '../../services/Auth';
import { LocalStorage } from "../../enums/LocalStorage";
import { withOrientationChange, isTablet, isDesktop } from 'react-device-detect';
import Menu from "../Menu/Menu";
import MenuIcon from '@material-ui/icons/Menu';
import { IOrientation, IMenuItem } from "../../interface/Sidebar";

const Header = (props: IOrientation) => {
    const { t } = useTranslation(['header', 'sidebar']);
    const { isLandscape } = props;
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;

    const [email, setEmail] = useState<string>('');
    const authData = localStorage.getItem(LocalStorage.Auth);
    const [menu, setMenuState] = React.useState({
        menuLeft: [
            // { label: t('header:about'), route: URLRoutes.About }, // Hide for first release
            // { label: t('header:sensor'), route: URLRoutes.Sensor }, // Hide for first release
            // { label: t('header:download'), route: URLRoutes.Download }, // Hide for first release
            { label: t('header:help_center'), route: URLRoutes.HelpCenter },
            { label: t('header:privacy'), route: URLRoutes.Privacy },
            { label: t('header:terms'), route: URLRoutes.Terms }

        ],
        menuRight: [
            { label: t('header:log_in'), route: URLRoutes.Login, class: '' },
            { label: t('header:create_account'), route: URLRoutes.CreateAccount, class: styles.button_rounded }
        ]
    });
    const [burgerMenu, setBurgerMenu] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (authData) {
            const data = JSON.parse(authData);
            setEmail(data.email);

            // Render Dashboard option only once
            const dashboardOptionExists = menu.menuLeft.filter(option => option.label === t('sidebar:dashboard'));
            if (dashboardOptionExists.length === 0) {
                setMenuState(prevState => ({
                    menuLeft: [{ label: t('sidebar:dashboard'), route: URLRoutes.Dashboard }, ...prevState.menuLeft],
                    menuRight: [...prevState.menuRight]
                }));
            }
        }
    }, [authData, t, menu.menuLeft]);

    const logout = async () => {
        menu.menuLeft.forEach(option => {
            if (option.label === t('sidebar:dashboard')) {
                const indexOf = menu.menuLeft.indexOf(option);
                if (indexOf !== -1) {
                    menu.menuLeft.splice(indexOf, 1);
                    
                    setMenuState(prevState => ({
                        menuLeft: menu.menuLeft,
                        menuRight: [...prevState.menuRight]
                    }));                
                }
            }
        });
        
        await AuthService.logout();
        window.location.href = URLRoutes.Home;
    }

    const redirect = (menuItem: IMenuItem) => {
        if (menuItem.label === t('header:sign_out')) {
            AuthService.logout();
        }
        history.push(menuItem.link);
        setBurgerMenu(false);
    }

    return <div className={styles.header_wrapper}>
                <div className={styles.header_options}>
                    <Link to={URLRoutes.Home}>
                        <span className="icon_svg icon_logo"></span>
                    </Link>
                    { isDesktopDevice ? 
                        <ul>
                            {
                                menu.menuLeft.map((item, index) => {
                                    return <li key={index}> <Link className={ item.route === location.pathname ?  styles.active_item : ''} to={item.route}> {item.label}</Link></li>
                                })
                            }
                        </ul>
                    : ''}

                </div>
                { isDesktopDevice ? 
                    <div className={styles.header_account}>
                        {!authData ? <div className={styles.header_login_section}>
                        {
                            menu.menuRight.map((item, index) => {
                                return <Link key={index} className={ item.route === location.pathname ? `${item.class} ${styles.active_item}` : item.class} to={item.route}> {item.label}</Link>
                            })
                        }
                            </div> : 
                            <div className={styles.header_user_section}> {/* Visible if user is logged in */}
                                <div className={styles.header_user_info}>
                                    <p>{email}</p>
                                    <Link to={URLRoutes.Home} onClick={logout}>{t('header:sign_out')}</Link>
                                </div>
                                <span className={styles.header_icon_wrapper}>
                                    <span className="icon icon_user_shield"></span>
                                </span>
                        </div>
                        }
                    </div>
                : <MenuIcon onClick={() => setBurgerMenu(true)} />}
        <Menu open={burgerMenu} click={() => setBurgerMenu(false)} redirect={(e: IMenuItem) => redirect(e)}/>
    </div>
}

export default withOrientationChange(Header);