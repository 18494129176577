import React, { useEffect, useState } from "react";
import styles from "./EmailActivation.module.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { URLRoutes } from "../../../enums/Routes";
import AuthService from "../../../services/Auth";
import { CircularProgress } from "@material-ui/core";
import { ActivateEmailResponse, StatusCode } from "../../../enums/Auth";
import DialogComponent from "../../../components/Dialog/Dialog";

enum EmailActivationType {
  Verified = 1,
  Expired = 2,
  Incorrect = 3,
}

const EmailActivation = () => {
  const { t } = useTranslation(["auth", "account"]);
  const history = useHistory();
  const [activationType, setActivationType] = useState<EmailActivationType>();
  const [token, setToken] = useState<string>('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = String(url.searchParams.get("token"));
    setToken(token);

    AuthService.activateEmail({ token: token }).then(
      () => {
        setActivationType(EmailActivationType.Verified);
      },
      (error) => {
        if (error.response.status === StatusCode.NotFound) {
          setActivationType(EmailActivationType.Incorrect);
        } else if (error.response.status === ActivateEmailResponse.TokenExpired && error.response.data.code === ActivateEmailResponse.TokenExpiredCode) {
          setActivationType(EmailActivationType.Expired)
        }
      }
    );
  }, [t]);

  const submit = () => {
    switch (activationType) {
      case EmailActivationType.Verified:
        return history.push(URLRoutes.Login);
      case EmailActivationType.Expired:
        return resendActivation();
      case EmailActivationType.Incorrect:
        return history.push(URLRoutes.ForgotPassword);
    }
  };

  const resendActivation = () => {
    AuthService.resendActivationEmail({token: token}).then(() => {
      setOpen(true);
    }, () => {
      setActivationType(EmailActivationType.Incorrect);
    })
  }

  const showDialog = () => {
    switch (activationType) {
      case EmailActivationType.Verified:
        return (
          <div className={styles.email_activation_wrapper}>
            <h3>{t("auth:email_verified")}</h3>
            <p>{t("auth:email_verified_msg")}</p>
            <button className={"btn_default_rounded"} onClick={submit}>
              {t("auth:sign_in")}
            </button>
          </div>
        );
      case EmailActivationType.Expired:
        return (
          <div className={styles.email_activation_wrapper}>
            <h3>{t("auth:email_verification_expired")}</h3>
            <p>{t("auth:email_verification_expired_msg")}</p>
            <button className={"btn_default_rounded"} onClick={submit}>
              {t("auth:resend_verification")}
            </button>
            <DialogComponent open={open} title={t('account:almost_finished')} content={t('account:sent_message_to_email_account')} buttonLabel={t('account:ok')} click={() => history.push(URLRoutes.Home)}/>
          </div>
        );
      case EmailActivationType.Incorrect:
        return (
          <div className={styles.email_activation_wrapper}>
            <h3>{t("auth:email_verification_incorrect")}</h3>
            <p>{t("auth:email_verification_incorrect_msg")}</p>
          </div>
        );
      default:
        return <CircularProgress />;
    }
  };

  return showDialog();
};

export default EmailActivation;
