import axios from './config/axios';
import { IInputPayment, IInputBilling } from '../interface/Sensor';

interface IRegisterSenor {
    sensorId?: string,
    planId?: string,
    creditCard?: IInputPayment,
    billingAddress?: IInputBilling
}

class SensorService {
    registerSensor = (sensorData: IRegisterSenor) =>  {
        return axios.post('/activation', sensorData);
    }

    getSensors = (params?: {}) => {
        return axios.get('/user/activation',  {params});
    };

    validate = (params: {id: string}) =>  {
        return axios.get('sensor/validate', {params});
    }

    cancelPlan = (id: string) =>  {
        return axios.post('/activation/cancel', {id: id});
    }

    changePlan = (params: {id: string, planId: string}) =>  {
        return axios.post('/activation/change-plan', params);
    }

    resumePlan = (params: {id: string}) =>  {
        return axios.post('/activation/uncancel', params);
    }
}

export default new SensorService();