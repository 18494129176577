import React, { KeyboardEvent } from "react";
import styles from './TextField.module.scss';
import TextField from '@material-ui/core/TextField';

interface IProps {
    change?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    focus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    type: string,
    placeholder?: string,
    errorMsg?: string,
    maxLength?: number,
    value?: string,
    defaultValue?: string
    fieldName?: string|undefined
    autoComplete?: string,
    required?: boolean,
    pattern?: string
}

const TextFieldComponent = (props: IProps) => {
    const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (props.pattern && !(new RegExp(props.pattern).test(e.key))) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    return <TextField error required={props.required} onKeyPress={onKeyPress} helperText={props.errorMsg} defaultValue={props.defaultValue} value={props.value} inputProps={{maxLength: props.maxLength}}
    className={`${styles.text_field}`} name={props.fieldName}  variant="outlined" autoComplete={props.autoComplete} type={props.type} placeholder={props.placeholder} onChange={props.change} onFocus={props.focus}/>
}

export default TextFieldComponent;