export enum URLRoutes {
    About = '/about',
    AccountDetails = '/account-details',
    CreateAccount = '/create-account',
    CreatePassword = '/create-password',
    Dashboard = '/dashboard',
    Download = '/download',
    Empty = '',
    ForgotPassword = '/forgot-password',
    EmailActivation = '/email-activation',
    HelpCenter = '/help-center',
    Home = '/homepage',
    Login = '/login',
    Payment = '/payment',
    PlanDetails = '/plan-details',
    Privacy = '/privacy',
    RegisterSensor = '/register-sensor',
    ResetPassword = '/reset-password',
    Sensor = '/sensor',
    Terms = '/terms',
    Version = '/version'
}