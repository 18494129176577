import axios from './config/axios';

interface IAccountData {
    email?: string,
    password?: string,
    handedness?: number
}

class AccountService {
    isLoggedIn = false;

    saveAccountData = async (userId: string, accountData: IAccountData) => {
        return axios.put('/user/' + userId, accountData);
    };

    validateEmail  = (params: {email?: string}) =>  {
        return axios.get('/user/validate-email', {params});
    }

    getUser = (id: string) =>  {
        return axios.get('/user/' + id);
    }
}

export default new AccountService();