import React from "react";
import styles from './AdminLayout.module.scss'
import { withOrientationChange, isTablet, isDesktop } from 'react-device-detect';
import { IOrientation } from "../../../interface/Sidebar";

interface IAdminLayout extends IOrientation{
    children: React.ReactNode,
}

const AdminLayout = (props: IAdminLayout) => {
    const { isLandscape } = props;
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;

    return (
        <div className={`${ styles.admin_layout_wrapper } ${isDesktopDevice ? styles.admin_layout_wrapper_web : ''}`}>
            {props.children}
        </div>
    );
}

export default withOrientationChange(AdminLayout);