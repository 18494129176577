import React from "react";
import image_sensor from '../../../assets/images/image_shaft_clip.png';
import styles from './RegisterSensor.module.scss'
import { IOrientation } from "../../../interface/Sidebar";
import { StatusState } from "../../../enums/Sensor";
import { URLRoutes } from "../../../enums/Routes";
import { isTablet, isDesktop, withOrientationChange } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import moment from "moment";

interface IRegisterSensor extends IOrientation {
    state: number,
    name?: string,
    id?: string,
    sensorId?: string,
    planId?: string,
    isCanceled?: boolean,
    billingDate?: string,
    expiresOn?: string,
    price?: string
    nextPlanId?: string,
    isPaid?: boolean
}

const RegisterSensor = (props: IRegisterSensor) => {
    const { t } = useTranslation(['sensor']);
    const history = useHistory();
    const { isLandscape } = props;
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;

    const redirect = () => {
        history.push(URLRoutes.RegisterSensor);
    }

    const redirectToPlanDetails = () => {
        history.push(URLRoutes.PlanDetails, {planDetails: props});
    }

    const getPlanLabel = (value?: string) => {
        if (value) {
            if (value.includes(t('sensor:month'))) {
                return value.includes(t('sensor:trial')) ? t('sensor:monthly_trial_plan') : t('sensor:monthly_plan');
            } else if (value.includes(t('sensor:year'))) {
                return value.includes(t('sensor:trial')) ? t('sensor:yearly_trial_plan') : t('sensor:yearly_plan');
            }
        }

        return ' ';
    }

    const renderHeaderSection = () => {
        if (props.isPaid === false) {
            return <div className={styles.register_sensor_header}>
                        <div className={styles.exclamation_wrapper}><span className="icon_svg icon_exclamation_circle"></span></div><p>{t('sensor:billing_issue_found')}</p>
                    </div>
        } else {
            switch(props.state) {
                case StatusState.Pending:
                    return <div className={styles.register_sensor_header}>
                                <div className={styles.sensor_icon_wrapper}><span className="icon_svg icon_sensor"></span></div><p>{t('sensor:sensor_registered')}</p>
                             </div>
                case StatusState.Active:
                    return props.isCanceled ?
                        <div className={styles.register_sensor_header}>
                            {/* Pending Cancelation */}
                            <div className={styles.pending_cancelation_wrapper}><span className="icon_svg icon_sensor"></span></div><p>{t('sensor:pending_cancelation')}</p><div className={styles.plan}>{getPlanLabel(props.planId) + ' ' + t('sensor:canceling_on') + ' ' + moment(props.billingDate).format('MM/DD/YYYY')}</div>
                        </div>
                        :
                        <div className={styles.register_sensor_header}>
                            <div className={styles.sensor_icon_wrapper}><span className="icon_svg icon_sensor"></span></div>
                            <p>{t('sensor:sensor_registered')}</p>
                            <div className={styles.plan}>{getPlanLabel(props.planId) + ' ' + t('sensor:renewing_on') + ' ' + moment(props.billingDate).format('MM/DD/YYYY')}</div>
                            { props.nextPlanId ? <div className={styles.plan}>{getPlanLabel(props.nextPlanId) + ' ' + t('sensor:starting_on') + ' ' + moment(props.billingDate).format('MM/DD/YYYY')}</div> : null }
                        </div>
                case StatusState.Expired:
                    return <div className={styles.register_sensor_header}>
                                <div className={styles.exclamation_wrapper}><span className="icon_svg icon_exclamation_circle"></span></div><p>{t('sensor:subscription_expired')}</p>
                            </div>
                case StatusState.Cancelled:
                    return <div className={styles.register_sensor_header}>
                                <div className={styles.cancelled_wrapper}><span className="icon_svg icon_sensor"></span></div><p>{t('sensor:plan_canceled')}</p><div className={styles.plan}>{getPlanLabel(props.planId) + ' ' + t('sensor:canceled_on') + ' ' + moment(props.billingDate).format('MM/DD/YYYY')}</div>
                            </div>
                case StatusState.Blocked:
                    return <div className={styles.register_sensor_header}>
                                <div className={styles.exclamation_wrapper}><span className="icon_svg icon_exclamation_circle"></span></div><p>{t('sensor:billing_issue_found')}</p>
                            </div>
                default:
                    return <div className={styles.register_sensor_header}><p>{t('sensor:no_sensor_is_registered')}</p></div>;
            }
        }
    }

    const renderCardBottomSection = () => {
        if (props.isPaid === false) {
            return  <div className={`${styles.register_sensor_review} ${styles.register_sensor_review_blocked}`}>
                        <p className={styles.sensor_name}>{props.name}</p>
                        <div className={styles.fix_billing_issue_button_section} >
                            <button className="btn_default_rounded" onClick={() => {history.push(URLRoutes.Payment, {sensorId: props.sensorId})}}>{t('sensor:fix_billing_information')}</button>
                        </div>
                    </div>
        } else {
            switch(props.state) {
                case StatusState.Pending:
                    return <div className={`${styles.register_sensor_review} ${styles.register_sensor_review_registration_plan}`}>
                                <p className={styles.sensor_status}>{t('sensor:your_sensor_is_registered')}</p>
                                <p className={styles.sensor_name}>{props.name}</p>
                                { props.planId ? <p className={styles.registration_plan_link} onClick={redirectToPlanDetails}>{t('sensor:view_registration_and_plan')}</p> : null }
                            </div>
                case StatusState.Active:
                    return <div className={`${styles.register_sensor_review} ${styles.register_sensor_review_registration_plan}`}>
                                <p className={styles.sensor_name}>{props.name}</p>
                                {/* Pending Switch */}
                                { props.nextPlanId ? <p className={styles.registration_plan_link} onClick={() => history.push(URLRoutes.PlanDetails, {planDetails: props, pendingSwitch: true})}>{t('sensor:view_registration_and_plan')}</p> :
                                 props.planId ? <p className={styles.registration_plan_link} onClick={redirectToPlanDetails}>{t('sensor:view_registration_and_plan')}</p> : null } {/* Active subscription */}
                            </div>
                case StatusState.Expired:
                    return  <div className={`${styles.register_sensor_review} ${styles.register_sensor_review_expired}`}>
                                <p className={styles.sensor_name}>{props.name}</p>
                            </div>
                case StatusState.Cancelled:
                    return  <div className={styles.register_sensor_review}>
                                <p className={styles.sensor_name}>{props.name}</p>
                                <div className={styles.start_new_button_section}>
                                    <button className="btn_default_rounded" onClick={() => {history.push(URLRoutes.RegisterSensor, {sensorId: props.sensorId})}}>{t('sensor:start_new_plan')}</button>
                                </div>
                            </div>
                case StatusState.Blocked:
                    return  <div className={`${styles.register_sensor_review} ${styles.register_sensor_review_blocked}`}>
                                <p className={styles.sensor_name}>{props.name}</p>
                                <div className={styles.fix_billing_issue_button_section} >
                                    <button className="btn_default_rounded" onClick={() => {history.push(URLRoutes.Payment, {sensorId: props.sensorId})}}>{t('sensor:fix_billing_information')}</button>
                                </div>
                            </div>
                default:
                    return <div className={styles.register_sensor_button_section}>
                                <button className="btn_default_rounded" onClick={redirect}>{t('sensor:register_your_sensor')}</button>
                            </div>;
            }
        }
    }

    return <div className={`${styles.register_sensor_wrapper} ${!isDesktopDevice ? styles.register_sensor_mobile_wrapper : styles.register_sensor_desktop_wrapper}`}>
                {renderHeaderSection()}
                <div className={styles.register_sensor_image}>
                    <img src={image_sensor} alt={t('sensor:register_your_sensor')}/>
                </div>
                {renderCardBottomSection()}
            </div>
}

export default withOrientationChange(RegisterSensor);
