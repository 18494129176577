import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'typeface-cabin';

// </React.StrictMode> tag has been removed during error from material-ui, https://github.com/mui-org/material-ui/issues/13394
ReactDOM.render(
    <App />,
    document.getElementById('root')
);
