import i18n from 'i18next';
import header from './en/header.json';
import account from './en/account.json';
import home from './en/home.json';
import auth from './en/auth.json';
import sidebar from './en/sidebar.json';
import sensor from './en/sensor.json';
import error from './en/error.json';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    header,
    account,
    home,
    auth,
    sidebar,
    sensor,
    error
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: ['header', 'account', 'home', 'auth', 'sidebar', 'sensor', 'error'],
  resources,
});