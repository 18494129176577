import React, { useState, useEffect } from "react";
import styles from './SensorRegistration.module.scss'
import { useTranslation } from 'react-i18next';
import NewSensor from "../../../components/Sensor/NewSensor/NewSensor";
import RegisterSensor from "../../../components/Sensor/RegisterSensor/RegisterSensor";
import { StatusState } from "../../../enums/Sensor";
import { withOrientationChange, isTablet, isDesktop } from 'react-device-detect';
import { IOrientation } from "../../../interface/Sidebar";
import Payment from "../../Payment/Payment";
import { useLocation } from "react-router-dom";

interface ISensorState {
    sensorId?: string
}

const Sensor = (props: IOrientation) => {
    const { isLandscape } = props;
    const {state} = useLocation<ISensorState>();
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;
    const { t } = useTranslation(['sidebar', 'sensor']);
    const [sensorId, setSensorId] = useState<string>();
    const [sensorName, setSensorName] = useState<string>();
    const [sensorExpiresOn, setSensorExpiration] = useState<string>();
    const [sensorBillingDate, setSensorBillingDate] = useState<string>();
    const [showNewSensor, setShowNewSensor] = useState<boolean>(true)
    const [endActivation, setActivationState] = useState<Boolean>(false);

    const updateSensor = (sensorId: string) => {
        setSensorId(sensorId);
        if (sensorId) {
            setShowNewSensor(false)
        }
    }


    useEffect(() => {
        if (state && state.sensorId) {
            updateSensor(state.sensorId);
        }
    }, [state]);

    const updateSensorValues = (name: string, expiresOn: string, billingDate: string) => {
        setSensorName(name); 
        setSensorExpiration(expiresOn);
        setSensorBillingDate(billingDate)
    }

    return <div className={`${ styles.sensor_wrapper  } ${isDesktopDevice ? '' : styles.sensor_wrapper_mobile}`}>
        { isDesktopDevice ? 
            ( showNewSensor || endActivation ? <h2>{t('sidebar:sensor')}</h2> : <p className={styles.sensor_cancel_subtitle} onClick={() => setShowNewSensor(true)}>{t('sensor:cancel_registration')}</p>) : null }
        { endActivation ?
            <RegisterSensor id={sensorId} name={sensorName}  expiresOn={sensorExpiresOn} billingDate={sensorBillingDate} state={StatusState.Active}/> :
            showNewSensor ? 
            <NewSensor onSensorUpdate={updateSensor}/> : 
            <Payment sensorId={sensorId} updateActivation={(state: boolean) => setActivationState(state)} updateSensorValues={updateSensorValues}/> 
        }
    </div>
}

export default withOrientationChange(Sensor);