import React, { FormEvent, useEffect, useState } from "react";
import styles from './NumberField.module.scss'

interface INumberField {
    input?: (event: FormEvent) => void,
    placeholder?: string,
    required?: boolean,
    minLength?: number,
    maxLength?: number
    name?: string
    onInputUpdate?: (value: number|string, name: string) => void,
    value?: number | string
}

const NumberField = (props: INumberField) => {
    const [value, setValue] = useState<number|string>();

    const handleChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;

        let formattedValue;
        if(target.name === 'cvc' || target.name === 'zipCode'){
            formattedValue = target.value ? target.value.replace(/\D/g, "") : '';
        }else{
            formattedValue = target.value ? Number(target.value.replace(/\D/g, "")) : '';
        }
        setValue(formattedValue);

        if (props.onInputUpdate) {
            props.onInputUpdate(formattedValue, target.name);
        }
    }

    useEffect(() => {
        setValue(props.value ? props.value : '');
    }, [props.value]);

    return (
        <input type="text" required={props.required} minLength={props.minLength} maxLength={props.maxLength} name={props.name} pattern="[0-9]*" placeholder={props.placeholder} onInput={(event: FormEvent<HTMLInputElement>) => handleChange(event)} value={value ? value : ''} className={styles.number_only_field}/>
    );
}

export default NumberField;