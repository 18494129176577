import React from 'react';
import styles from './Version.module.scss';
import * as buildVersion from '../../version.json';

const Version = () => {
    return <div className={styles.version_wrapper}>
        {buildVersion.version}
    </div>
};

export default Version;