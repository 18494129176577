import React, { useState, useEffect } from "react";
import styles from './ResetPassword.module.scss'
import { useTranslation } from 'react-i18next';
import TextField from "../../../components/TextField/TextField";
import AuthService from '../../../services/Auth';
import { useHistory } from "react-router-dom";
import { URLRoutes } from "../../../enums/Routes";
import { ISnackbar } from "../../../interface/Snackbar";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { isStrongPassword } from "../../../components/Validator/PasswordValidation/PasswordValidation";

const ResetPassword = () => {
    const { t } = useTranslation(['auth', 'account']);
    const [password, setPassword] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const history = useHistory();
    const [snackbar, setSnackbar] = useState<ISnackbar>({open: false, severity: 'success', message: ''});
    const [isButtonDisabled, setDisabledButtonState] = useState(true); // Submit button is disabled by default, enables on input change
    const [showErrorMsg, setErrorMsg] = useState<string>('');

    useEffect(() => {
        const url = new URL(window.location.href);
        const token = String(url.searchParams.get("pwd_token"));
        setToken(token)

        const checkToken = async (token: string) => {
            try {
                const {data} = await AuthService.checkResetPasswordToken({token: token});
    
                if (data !== token) {
                    history.push(URLRoutes.Login)
                }
            } catch (ex) {
                history.push(URLRoutes.Login)
            }
        }

        checkToken(token);
    }, [history]);

    const submit = async () => {
        try {
            await AuthService.saveNewPassword({token: token, password: password});
            setSnackbar({
                open: true,
                severity: 'success',
                message: t('auth:password_changed')
            })

            setTimeout(() => {
                history.push(URLRoutes.Login);
            }, 3000); // Delay is set so user can see success message before switching the page
        } catch (ex) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: t('auth:password_not_changed')
            });       
            setDisabledButtonState(true);
        }
    }

    const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value)
        setErrorMsg(!isStrongPassword(value) ? t('account:password_not_valid') : '');
        setDisabledButtonState(!isStrongPassword(value))
    }

    return <div className={styles.reset_password_wrapper}>
            <h3>{t('auth:create_new_password')}</h3>
            <p className={styles.reset_password_subheader}>{t('account:password_requirements')}</p>
            <form  noValidate autoComplete="off">
                <TextField errorMsg={showErrorMsg} placeholder={t('auth:password')} type='password' change={(e: React.ChangeEvent<HTMLInputElement>) => onChangePassword(e)}/>
            </form>
            <button className={`${isButtonDisabled ? 'btn_default_rounded disabled' : 'btn_default_rounded'}`} onClick={submit}>{t('auth:submit')}</button>
            <Snackbar open={snackbar.open} severity={snackbar.severity} message={snackbar.message}/>
        </div>
}

export default ResetPassword;