import React from "react";
import styles from './ExistingAccount.module.scss'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { URLRoutes } from "../../../enums/Routes";

interface IExistingAccount {
    newAccount?: boolean
}
const ExistingAccount = (props: IExistingAccount) => {
    const { t } = useTranslation(['auth', 'account']);
    const path = props.newAccount ? URLRoutes.CreateAccount : URLRoutes.Login;
    const routeLabel = props.newAccount ? t('account:sign_up') : t('auth:sign_in');

    return <div className={styles.existing_account_wrapper}>
            <p>{props.newAccount ? t('auth:do_not_have_account') : t('auth:already_have_an_account')}</p>
            <Link to={path}>{routeLabel}</Link>
        </div>
}

export default ExistingAccount;