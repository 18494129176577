import React from "react";
import Header from "../Header/Header";
import styles from './Layout.module.scss';
import Sidebar from "../Sidebar/Sidebar";
import { URLRoutes } from "../../enums/Routes";
import { useLocation } from 'react-router-dom';
import { LocalStorage } from "../../enums/LocalStorage";

interface ILayout {
    children: React.ReactNode,
    computedMatch?: Object,
    location?: Object
}

const Layout = (props: ILayout) => {
    const location = useLocation();

    const checkSidebarVisibility = () => {
        const allowedRoutes: string[] = [URLRoutes.Dashboard, URLRoutes.RegisterSensor, URLRoutes.AccountDetails, URLRoutes.Payment, URLRoutes.PlanDetails]; // Admin screens that contains sidebar menu
        return allowedRoutes.includes(location.pathname);
    }

    return (
        <div className={styles.layout_wrapper}>
            <Header/>
            {localStorage.getItem(LocalStorage.Auth) && checkSidebarVisibility() ? <Sidebar/> : null}
            <div className={styles.layout_content}>
                {props.children}
            </div>
        </div>
    );
}

export default Layout;