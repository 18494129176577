import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import Layout from "../Layout/Layout";
import { URLRoutes } from "../../enums/Routes";
import CreateAccount from "../Account/CreateAccount/CreateAccount";
import CreatePassword from "../Account/CreatePassword/CreatePassword";
import Home from '../../containers/Home/Home';
import About from '../../containers/About/About';
import Sensor from '../../containers/Sensor/Sensor';
import Download from '../../containers/Download/Download';
import HelpCenter from '../../containers/HelpCenter/HelpCenter';
import AuthLayout from "../Layout/AuthLayout/AuthLayout";
import Login from "../../containers/Auth/Login/Login";
import Terms from "../../containers/Terms/Terms";
import PrivacyPolicy from "../../containers/PrivacyPolicy/PrivacyPolicy";
import Dashboard from "../../containers/Admin/Dashboard/Dashboard";
import { LocalStorage } from "../../enums/LocalStorage";
import AdminLayout from "../Layout/AdminLayout/AdminLayout";
import AccountDetails from "../../containers/Admin/AccountDetails/AccountDetails";
import SensorRegistration from "../../containers/Admin/SensorRegistration/SensorRegistration";
import ForgotPassword from "../../containers/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "../../containers/Auth/ResetPassword/ResetPassword";
import { useIdleTimer } from 'react-idle-timer'
import AuthService from '../../services/Auth';
import EmailActivation from "../../containers/Auth/EmailActivation/EmailActivation";
import Payment from "../../containers/Payment/Payment";
import PlanDetails from "../Payment/PlanDetails/PlanDetails";
import Version from "../../containers/Version/Version";

const Routing = () => {
  const authGuard = (Component: React.ComponentType) => () => {
    return localStorage.getItem(LocalStorage.Auth) ? (
      <Layout><AdminLayout><Component /></AdminLayout></Layout>
    ) : (
      <Redirect to={URLRoutes.Login} />
    );
  };


  const handleOnIdle = () => {
    if (localStorage.getItem(LocalStorage.Auth)) {
      AuthService.logout();
    }
  }
  
  useIdleTimer({
    timeout: 3600000,
    onIdle: handleOnIdle,
    debounce: 500
  });

  return <BrowserRouter>
            <Switch>
                {/* Home screen */}
                <Route exact path={[URLRoutes.Empty, URLRoutes.Home]}>
                  <Layout>
                    <Home />
                  </Layout>
                </Route>
                {/* Login screen */}
                <Route exact path={URLRoutes.Login}>
                  <Layout>
                    <AuthLayout>
                      <Login />
                    </AuthLayout>
                  </Layout>
                </Route>
                {/* Forgot Password screen */}
                <Route exact path={URLRoutes.ForgotPassword}>
                  <Layout>
                    <AuthLayout>
                      <ForgotPassword />
                    </AuthLayout>
                  </Layout>
                </Route>
                {/* Reset Password screen */}
                <Route exact path={URLRoutes.ResetPassword}>
                  <Layout>
                    <AuthLayout>
                      <ResetPassword />
                    </AuthLayout>
                  </Layout>
                </Route>
                {/* Email Activation screen */}
                <Route exact path={URLRoutes.EmailActivation}>
                  <Layout>
                    <AuthLayout>
                      <EmailActivation />
                    </AuthLayout>
                  </Layout>
                </Route>
                {/* Create Account screen */}
                <Route path={URLRoutes.CreateAccount}>
                  <Layout>
                    <AuthLayout>
                      <CreateAccount />
                    </AuthLayout>
                  </Layout>
                </Route>
                {/* Create Password screen */}
                <Route path={URLRoutes.CreatePassword}>
                  <Layout>
                    <AuthLayout>
                      <CreatePassword/>
                    </AuthLayout>
                  </Layout>
                </Route>
                {/* About screen */}
                <Route exact path={URLRoutes.About}>
                  <Layout>
                    <About />
                  </Layout>
                </Route>
                {/* Sensor screen */}
                <Route exact path={URLRoutes.Sensor}>
                  <Layout>
                    <Sensor />
                  </Layout>
                </Route>
                {/* Download screen */}
                <Route exact path={URLRoutes.Download}>
                  <Layout>
                    <Download />
                  </Layout>
                </Route>
                {/* Help center screen */}
                <Route exact path={URLRoutes.HelpCenter}>
                  <Layout>
                    <HelpCenter />
                  </Layout>
                </Route>
                 {/* Terms screen */}
                <Route exact path={URLRoutes.Terms}>
                  <Layout>
                    <Terms />
                  </Layout>
                </Route>
                {/* Privacy screen */}
                <Route exact path={URLRoutes.Privacy}>
                  <Layout>
                    <PrivacyPolicy />
                  </Layout>
                </Route>
                {/* Version */}
                <Route exact path={URLRoutes.Version}>
                  <Layout>
                    <Version />
                  </Layout>
                </Route>
                
                {/* Admin section */}
                <Route path={URLRoutes.Dashboard} render={authGuard(Dashboard)}></Route>
                <Route path={URLRoutes.RegisterSensor} render={authGuard(SensorRegistration)}></Route>
                <Route path={URLRoutes.AccountDetails} render={authGuard(AccountDetails)}></Route>
                <Route path={URLRoutes.Payment} render={authGuard(Payment)}></Route>
                <Route path={URLRoutes.PlanDetails} render={authGuard(PlanDetails)}></Route>

                {/* Page not found */}
                <Route path="*">
                  <Redirect to={URLRoutes.Home} />
                </Route>
            </Switch>
        </BrowserRouter>
}

export default Routing;