import React from 'react';
import styles from './Loading.module.scss'
import { CircularProgress } from '@material-ui/core';

interface ILoading {
    loading: boolean
}

const Loading = (props: ILoading) => {
    return props.loading ? <div className={styles.loading_wrapper}> <CircularProgress/></div> : null;
}

export default Loading;

