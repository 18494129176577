import React, { useState } from "react";
import styles from './ForgotPassword.module.scss'
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { URLRoutes } from "../../../enums/Routes";
import TextField from "../../../components/TextField/TextField";
import AuthService from '../../../services/Auth';
import validator from "validator";
import DialogComponent from "../../../components/Dialog/Dialog";
import { StatusCode } from "../../../enums/Auth";
import { ResponseCode } from "../../../enums/Auth";
import { AxiosError } from "axios";

const ForgotPassword = () => {
    const { t } = useTranslation(['auth', 'account']);
    const history = useHistory();
    const [email, setEmail] = useState<string>('');
    const [isButtonDisabled, setDisabledButtonState] = useState(true); // Submit button is disabled by default, enables on input change
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [openDialog, setOpenDialog] = useState(false);

    const submit = async () => {
        try {
            await AuthService.getResetPasswordToken({email: email.toLowerCase()});
            setDisabledButtonState(true);
            setOpenDialog(true);
        } catch (err) {
            const error = err as AxiosError;

            // If Response status is 404 (Not Found), display error message
            if (error.response?.status === StatusCode.NotFound) {
                setErrorMessage(t('account:email_is_not_registered'))
            }

            // Is user tries to to login with an inactive account, show an error message under the email input with the message
            if (error.response?.status === StatusCode.UnprocessableEntity && error.response?.data.code === ResponseCode.UserAccountPendingActivation) {
                setErrorMessage(t('account:account_is_not_active_yet'))
            }
            setDisabledButtonState(true);
        }
    }

    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value)
        setDisabledButtonState(!validator.isEmail(value));
        setErrorMessage('');
    }

    return <div className={styles.forgot_account_wrapper}>
            <h3>{t('auth:forgot_password')}</h3>
            <p className={styles.forgot_account_subtitle}>{t('auth:submit_your_email')}</p>
            <form  noValidate autoComplete="off">
                <TextField errorMsg={errorMessage} placeholder={t('auth:email')} type='text' change={(e: React.ChangeEvent<HTMLInputElement>) => onEmailChange(e)}/>
            </form>
            <button className={`${isButtonDisabled ? 'btn_default_rounded disabled' : 'btn_default_rounded'}`} onClick={submit}>{t('auth:submit')}</button>
            <Link to={URLRoutes.Login} className={styles.back_link} >{t('auth:back_to_login')}</Link>
            <DialogComponent open={openDialog} title={t('account:check_email_title')} content={t('account:check_email_msg')} buttonLabel={t('account:ok')} click={() => history.push(URLRoutes.Home)}/>
        </div>
}

export default ForgotPassword;