export enum ActivateEmailResponse {
    TokenExpired = 422,
    TokenExpiredCode = 150
}

export enum StatusCode {
    UnprocessableEntity = 422,
    Unauthorized = 401,
    NotFound = 404
}

export enum ResponseCode {
    UserAccountPendingActivation = 100,
    EmailAlreadyTaken = 110,
    InvalidCreditCardData = 140,
    CreditCardExpired = 141
}