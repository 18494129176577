export enum StatusState {
    Default = 0,
    Pending = 1, // it's active subscription, starts in future
    Active = 2, // registered
    Blocked = 3,
    Cancelled = 4,
    Expired = 5
}

export enum Country {
    USA = 'US',
    Canada = 'CA'
}

export enum Subscription {
    ErrorResumeCode = 162
}