import React from "react";
import styles from "./HomeMain.module.scss";
import DescriptionArea from "../../DescriptionArea/DescriptionArea";
import { useTranslation } from "react-i18next";
import image_two_iphones from "../../../assets/images/image_two_iphones.png";
import image_averages from "../../../assets/images/image_averages_mobile.png";
import image_alerts from "../../../assets/images/image_alerts_mobile.png";

import { isDesktop, isTablet, withOrientationChange } from "react-device-detect";
import { IOrientation } from "../../../interface/Sidebar";

const HomeMain = (props: IOrientation) => {
  const { t } = useTranslation(["home"]);
  const { isLandscape } = props;
  const isDesktopDevice = (isTablet && isLandscape) || isDesktop;

  return isDesktopDevice ? (
    <div className={styles.home_main_wrapper}>
      <div className={styles.home_main_content}>
        <DescriptionArea subContent={t("home:analyze_all_aspects")} title={t("home:scientific_putting_system")} />
        <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/biomech-putt/id1562724109?itsct=apps_box_badge&itscg=30200">
          <button className={styles.home_apple_download}></button>
        </a>
      </div>
      <div className={styles.home_main_image}>
        <img src={image_two_iphones} alt={t("home:scientific_putting_system_v2")}/>
      </div>
    </div>
  ) : (
    <div className={styles.home_main_wrapper_mobile}>
        <div className={styles.desc_area_container}>
            <DescriptionArea center={true} subContent={t("home:analyze_all_aspects")} title={t("home:scientific_putting_system")} />
        </div>
        <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/biomech-putt/id1562724109?itsct=apps_box_badge&itscg=30200">
          <button className={styles.home_apple_download}></button>
        </a>
        <div className={styles.home_main_image}>
            <img src={image_averages} alt={t("home:scientific_putting_system_v2")}/>
            <img src={image_alerts} alt={t("home:scientific_putting_system_v2")}/>
        </div>
    </div>
  );
};

export default withOrientationChange(HomeMain);
