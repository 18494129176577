import React, { useState, ChangeEvent } from "react";
import styles from './CreatePassword.module.scss'
import { useTranslation } from 'react-i18next';
import ExistingAccount from "../../Auth/ExistingAccount/ExistingAccount";
import { Checkbox } from "@material-ui/core";
import { useLocation, Link, useHistory, Redirect } from "react-router-dom";
import AuthService from '../../../services/Auth';
import { URLRoutes } from "../../../enums/Routes";
import TextField from "../../TextField/TextField";
import DialogComponent from "../../Dialog/Dialog";
import { isStrongPassword } from "../../Validator/PasswordValidation/PasswordValidation";

interface IAccountState {
    email: string,
    firstName: string,
    lastName: string
  }

const CreatePassword = () => {
    const { t } = useTranslation(['account']);
    let location = useLocation<IAccountState>();
    const history = useHistory();

    const [password, setPassword] = useState<string>('');
    const [showErrorMsg, setErrorMsg] = useState<string>('');
    const [terms, setTerms] = useState<boolean>(false);
    const [isButtonDisabled, setDisabledButtonState] = useState(true); // Sign up button is disabled by default, enables on input change
    const [open, setOpen] = useState(false);

    const signUp = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setDisabledButtonState(true);

        if (location.state) {
            const signUpData = {
                email: location.state.email,
                firstName: location.state.firstName,
                lastName: location.state.lastName,
                password: password,
            };
            try {
                const {data} = await AuthService.signUp(signUpData);
                    if (data) {
                        setOpen(true);
                    }
                location.state.email = '';
            } catch (error) {
            }
        }
    }

    const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setErrorMsg('');
        setPassword(value);

        if (!isStrongPassword(value)) {
            setErrorMsg(t('account:password_not_valid'))
        }

        setDisabledButtonState(!isStrongPassword(value) || !terms)
    }

    const onSetTerms = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        setTerms(value); 
        setDisabledButtonState(!value || !isStrongPassword(password));
    }

    return (!location.state ? (<Redirect to={URLRoutes.CreateAccount}/>) : <div className={styles.layout_wrapper}>
        <div className={styles.create_password_wrapper}>
            <h3>{t('account:create_password')}</h3>
            <p className={styles.create_password_subheader}>{t('account:password_requirements')}</p>
            <form  autoComplete="off">
                <TextField errorMsg={showErrorMsg} placeholder={t('account:password')} type='password' change={(e: React.ChangeEvent<HTMLInputElement>) => onChangePassword(e)}/>
            </form>
            <div className={styles.checkbox_section}>
                <p className={styles.terms_of_service}><span>{t('account:terms_of_service_text')}</span> <b> <Link to={URLRoutes.Terms} target="_blank" rel="noopener noreferrer">{t('account:terms_of_service')}.</Link></b></p>
                <Checkbox color="primary" onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSetTerms(e)}/>
            </div>
            <button onClick={(e) => signUp(e)} className={`${isButtonDisabled ? 'btn_default_rounded disabled' : 'btn_default_rounded'}`}>{t('account:sign_up')}</button>
            <ExistingAccount/>
            <DialogComponent open={open} title={t('account:almost_finished')} content={t('account:sent_message_to_email_account')} buttonLabel={t('account:ok')} click={() => history.push(URLRoutes.Home)}/>
        </div>
    </div>)
}

export default CreatePassword;