import React, { useState, useEffect } from "react";
import styles from './PaymentCard.module.scss';
import { useTranslation } from 'react-i18next';
import sensor from '../../../assets/images/sensor.png';
import check_solid from '../../../assets/images/check_solid.png';
import { IPaymentButtons } from "../../../interface/Sensor";
import PaymentService from "../../../services/Payment";
import moment from 'moment';

interface IPayment {
    switchButtons: Array<IPaymentButtons>
}

interface IPaymentCard {
    updatePlanId?: (planId: string) => void,
    checkTrial?: (isTrial: boolean) => void,
    sensorId?: string
}

interface IPlans {
    id: string,
    isTrial: boolean,
    price: number,
    startDate: Date
}

const PaymentCard = (props: IPaymentCard) => {
    const { t } = useTranslation(['sensor']);
    const [button, setButtonState] = useState<IPayment>({switchButtons: []});
    const [plans, setPlans] = useState<IPlans[]>([]);
    const [activePlan, setActivePlan] = useState<string | undefined>();
    const [isTrial, setTrialState] = useState<boolean>(false);


    const selectButton = (item: IPaymentButtons, index: number) => {
        setButtonState({
            switchButtons: button.switchButtons.map((buttons, indexItem) => ({
              ...buttons,
              active: index === indexItem && item.label === buttons.label,
              id: plans[indexItem].id
            }))
        });
        setActivePlan(plans[index].id);
        
        if (props.checkTrial) {
            props.checkTrial(plans[index].isTrial);
        }

        if (props.updatePlanId) {
            props.updatePlanId(plans[index].id ? plans[index].id : '');
        }
    }

    useEffect(() => {
        if (props.sensorId) {
            PaymentService.listOfPlans({sensorId: props.sensorId}).then(async response => {
                const data = response.data;
                setPlans(data);
                setActivePlan(data[0].id); // Monthly is set by default as active
                setTrialState(data[0].isTrial);

                if (props.checkTrial) {
                    props.checkTrial(data[0].isTrial);
                }

                if (props.updatePlanId) {
                    props.updatePlanId(data[0].id);
                }
                /** 
                 * calculate annual percentage
                 * monthlyPrice*12 : 100 = (montjhlyPrice*12 - yearlyPrice) : x
                 * x = (100 x (montjhlyPrice*12 - yearlyPrice)) / (monthlyPrice*12)
                 */
                const monthlyPrice = data[0].price;
                const yearlyPrice = data[1].price;
                const monthlyTotal = monthlyPrice * 12;
                const percentage = Math.round((100 * (monthlyTotal - yearlyPrice)) / monthlyTotal);

                setButtonState({
                    switchButtons: [
                        { label: t('sensor:monthly'), active: true}, 
                        { label: t('sensor:yearly_save', { percentage: percentage}), active: false}
                    ]
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sensorId]);

    return <div className={styles.payment_card_wrapper}>
        <div className={styles.payment_card_elements}>
            <div className={styles.payment_switch_btn_section}>
            {
                button.switchButtons.map((item, index) => {
                    return <button key={index} 
                    className={`${styles.payment_button_switch} btn_default_rounded ${item.active ? styles.active: ''}`}
                    onClick={() => selectButton(item, index)}>{item.label}</button>
                })
            }
            </div>
            {
                plans.map((item, index) => {    
                    return <div className={`${styles.payment_sensor_wrapper} ${activePlan !== item.id ? styles.hidden : ''}`} key={index}>
                    <img className={styles.payment_sensor_img} src={sensor} alt={'sensor'}/>
                    <h2 className={styles.payment_title}>{t('sensor:sensor_registration')}</h2>
                    <h1 className={styles.payment_price}>${item.price}</h1>
                    {item.isTrial ? <p className={styles.payment_subtitle}>{t('sensor:includes_free_trial')}</p> : null }
    
                    <div className={styles.check_solid_section}>
                        <img className={styles.check_solid} src={check_solid} alt={'checksolid'}/>
                        <p className={styles.payment_description}>{t('sensor:register_your_sensor')}</p>
                    </div>
                    
                    <div className={styles.check_solid_section}>
                        <img className={styles.check_solid} src={check_solid} alt={'checksolid'}/>
                        <p className={styles.payment_description}>{t('sensor:get_access_to_all_features_in_app')}</p>
                    </div>
    
                    <div className={styles.check_solid_section}>
                        <img className={styles.check_solid} src={check_solid} alt={'checksolid'}/>
                        <p className={styles.payment_description}>{t('sensor:improve_your_putting_in_real_time')}</p>
                    </div>
                    <div className={styles.payment_sensor_total}>
                        <div className={styles.order_total_section}>
                            <p className={styles.total_today}>{t('sensor:total_today', {price: isTrial ? '0.00' : item.price})}</p>
                            <p className={styles.order_total}>
                                {t('sensor:order_total', {price: item.price, type: index === 0 ? t('sensor:month') : t('sensor:year')}) + ' ' + moment(item.startDate).format('MM/DD/YYYY') + ' (' +t('sensor:sales_tax_may_apply') + ')'}
                            </p>
                        </div>
                    </div>
                </div>
                })
            }
        </div>
    </div>
}

export default PaymentCard;