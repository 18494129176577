import React from "react";
import styles from './AuthLayout.module.scss'
import { LocalStorage } from "../../../enums/LocalStorage";
import { Redirect } from "react-router-dom";
import { URLRoutes } from "../../../enums/Routes";

interface IAuthLayout {
    children: React.ReactNode,
}

const AuthLayout = (props: IAuthLayout) => {
    // If we are logged in and try to access the Login, Forgot/Reset Password and Create Account/Password pages, we are redirected to the dashboard page
    if (localStorage.getItem(LocalStorage.Auth)) {
        return <Redirect to={URLRoutes.Dashboard} />;
    }

    return (
        <div className={styles.auth_layout_wrapper}>
            {props.children}
        </div>
    );
}

export default AuthLayout;