import React, { useState, useEffect } from "react";
import styles from './PaymentDetails.module.scss';
import { useTranslation } from 'react-i18next';
import TextField from "../../TextField/TextField";
import { IInputPayment, IInputPaymentState } from "../../../interface/Sensor"
import { withOrientationChange, isTablet, isDesktop } from 'react-device-detect';
import { IOrientation } from "../../../interface/Sidebar";
import NumberField from "../../NumberField/NumberField";
import CreditCardField from "../../CreditCardField/CreditCardField";

interface IPaymentDetails extends IOrientation {
   updatePaymentData?: (arg0: IInputPayment) => void,
   valid: (value: boolean) => void,
   data?: IInputPayment
}

const PaymentDetails = (props: IPaymentDetails)  => {
    const { t } = useTranslation(['sensor', 'account']);
    const { isLandscape } = props;
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;
    const [input, setInputState] = useState<IInputPayment>({
      holderName : '',
      number: '',
      numberLastFour : '',
      cvc : '',
      expirationMonth: 0,
      expirationYear: 0
   });

   const [field, setFieldState] = useState<IInputPaymentState>({
      holderName: false,
      number: false,
      cvc : false,
      expirationMonth: false,
      expirationYear: false
   }); 

   useEffect(() => {
      if (props.data) {
         setInputState(props.data);
      }
   }, [props.data]);

   useEffect(() => {
      if (props.updatePaymentData) {
         props.updatePaymentData(input);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [input]);

   useEffect(() => {
      checkValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [input]);

   const checkValidation = () => {
      if (input.holderName && input.number && input.cvc && String(input.cvc).length >= 3 && String(input.cvc).length <= 4 &&
      input.expirationMonth && String(input.expirationMonth).length >= 1 && String(input.expirationMonth).length <= 2 &&
      input.expirationYear && String(input.expirationYear).length >= 1 && String(input.expirationYear).length <= 2) {
         props.valid(true);
      } else {
         props.valid(false);
      }
   }

   const onInputChange = (value: string|number, name: string) => {
      setInputState({...input, [name]: value});
      
      if (!input.number && !input.cvc && name !== 'number' && name !== 'cvc') {
         // number and cvc field should be displayed as required if user change any other payment field
         setFieldState({...field, number: true, cvc: true, [name]: String(value).length === 0});
      } else {
         setFieldState({...field, [name]: String(value).length === 0});
      }
   }

    return  <div className={`${isDesktopDevice ? styles.payment_details_wrapper : styles.payment_mobile_details_wrapper}`}>
         <h2 className={styles.payment_details_title}>{t('sensor:payment_details')}</h2>
         <div className={styles.payment_details_credit_card_name}>
            <p className={styles.payment_details_input_title}>{t('sensor:name_on_card')}</p>
            <TextField type='text' required={field.holderName} maxLength={50} value={input.holderName} change={(e) => onInputChange(e.currentTarget.value, e.currentTarget.name)} fieldName="holderName"/>
         </div>

         <div className={styles.payment_details_credit_card_numbers_section}>
            <div className={styles.payment_details_credit_card_number}>
               <p className={styles.payment_details_input_title}>{t('sensor:credit_card_number')}</p>
               <CreditCardField name="number" value={Number(input.number)} placeholder={`${'************' + input.numberLastFour}`} required={field.number}
               onInputUpdate={(value, name) => onInputChange(String(value), name)}/>
            </div>

            <div className={styles.payment_details_credit_security_code}>
               <p className={styles.payment_details_input_title}>{t('sensor:security_code')}</p>
               <NumberField name="cvc" value={input.cvc} placeholder='***' required={field.cvc} minLength={3} maxLength={4} onInputUpdate={onInputChange}/>
            </div>

            <div className={styles.payment_details_expiration_section}>
               <div className={styles.payment_details_credit_expiration_month}>
                  <p className={styles.payment_details_input_title}>{t('sensor:expiration')}</p>
                  <NumberField required={field.expirationMonth} minLength={1}  maxLength={2} value={input.expirationMonth} placeholder={t('account:month_short')} name="expirationMonth" onInputUpdate={onInputChange}/>
               </div>

               <div className={styles.payment_details_credit_expiration_year}>
                  <p className={styles.payment_details_date_input_title}>{t('sensor:date')}</p>
                  <NumberField required={field.expirationYear} minLength={1}  maxLength={2} value={input.expirationYear} placeholder={t('account:year_short')} name="expirationYear" onInputUpdate={onInputChange}/>
               </div>
            </div>
         </div>
    </div>
}

export default withOrientationChange(PaymentDetails);