import React from 'react';
import styles from './Home.module.scss'
import HomeFooter from '../../components/Home/HomeFooter/HomeFooter';
import HomeMeasure from '../../components/Home/HomeMeasure/HomeMeasure';
import HomeMain from '../../components/Home/HomeMain/HomeMain';
import { isDesktop, isTablet, withOrientationChange } from 'react-device-detect';
import { IOrientation } from '../../interface/Sidebar';

const Home = (props: IOrientation) => {
    const { isLandscape } = props;
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;

    return <div className={styles.home_wrapper}>
        <HomeMain />
        <HomeMeasure />
        { isDesktopDevice ? <HomeFooter /> : null }
    </div>
}

export default withOrientationChange(Home);