import React, { ChangeEvent, FormEvent, useState } from "react";
import styles from './Login.module.scss'
import { Link } from "react-router-dom";
import ExistingAccount from "../../../components/Auth/ExistingAccount/ExistingAccount";
import { useTranslation } from 'react-i18next';
import TextField from "../../../components/TextField/TextField";
import AuthService from '../../../services/Auth';
import { Redirect } from "react-router-dom";
import { URLRoutes } from "../../../enums/Routes";
import validator from "validator";
import { StatusCode } from "../../../enums/Auth";
import { ResponseCode } from "../../../enums/Auth";
import { AxiosError } from "axios";

const Login = () => {
    const { t } = useTranslation(['auth', 'account']);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isRedirectToDashboard, setRedirectToDashboard] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [isButtonDisabled, setDisabledButtonState] = useState(true); // Login button is disabled by default, enables on input change

    const login = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isButtonDisabled) { return; } // in case a user presses enter, but the button is disabled
    
        const logInData = {
            username: username.toLowerCase(),
            password: password
        };
        try {
          const {data} = await AuthService.login(logInData)
          if (data) {
            setRedirectToDashboard(true);
          }
        } catch (err) {
            const error = err as AxiosError;

            // If Response status is 401 (unauthorized), display error message
            if (error.response?.status === StatusCode.Unauthorized) {
                setErrorMsg(t('account:password_or_email_is_incorrect'))
            }

            // Is user tries to to login with an inactive account, show an error message under the email input with the message
            if (error.response?.status === StatusCode.UnprocessableEntity && error.response.data.code === ResponseCode.UserAccountPendingActivation) {
                setErrorMsg(t('account:account_is_not_active_yet'))
            }

            setDisabledButtonState(true);
        }
    }

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setUsername(value); 
        setErrorMsg('');

        setDisabledButtonState(!value || !password);

        if (validator.isEmail(value)) {
            if (password) {
                setDisabledButtonState(false);
            }
        } else {
            setDisabledButtonState(true);
        }
    }

    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value); 
        setDisabledButtonState(!value || !validator.isEmail(username))
    }


    return (isRedirectToDashboard ? (<Redirect to={URLRoutes.Dashboard}/>) : <div className={styles.login_wrapper}>
                <p className={styles.login_title}>{t('auth:log_in_account')}</p>
                <form  noValidate autoComplete="off" onSubmit={login}>
                    <TextField errorMsg={errorMsg} placeholder={t('auth:email')} type='text' change={(e: ChangeEvent<HTMLInputElement>) => onEmailChange(e)} />
                    <TextField placeholder={t('auth:password')} type='password' change={(e: ChangeEvent<HTMLInputElement>) => onPasswordChange(e)} />
                    <Link to={URLRoutes.ForgotPassword}>{t('auth:forgot_password')}</Link>
                    <button type="submit" className={`${isButtonDisabled ? 'btn_default_rounded disabled' : 'btn_default_rounded'}`}>{t('auth:login')}</button>
                </form>
                <div className={styles.login_existing_account}>
                    <ExistingAccount newAccount={true}/>
                </div>
        </div>)
}

export default Login;