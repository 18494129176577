import React, { useEffect, useState } from "react";
import styles from "./AccountDetails.module.scss";
import { useTranslation } from "react-i18next";
import AccountDetailsCard from "../../../components/Account/AccountDetailsCard/AccountDetailsCard";
import PaymentDetails from "../../../components/Payment/PaymentDetails/PaymentDetails";
import BillingDetails from "../../../components/Payment/BillingDetails/BillingDetails";
import {
  isDesktop,
  isTablet,
  withOrientationChange,
} from "react-device-detect";
import { IOrientation } from "../../../interface/Sidebar";
import { LocalStorage } from "../../../enums/LocalStorage";
import AccountService from "../../../services/Account";
import PaymentService from "../../../services/Payment";
import { IInputBilling, IInputPayment } from "../../../interface/Sensor";
import Loading from "../../../components/Loading/Loading";
import { ISnackbar } from "../../../interface/Snackbar";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { getCreditCardErrorMsg } from "../../../helpers/ErrorHelper";

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  hasPayment: boolean;
  handedness: number;
  accountStatus: number;
  createdOn: Date;
  lastModified: Date;
}

const AccountDetails = (props: IOrientation) => {
  const { t } = useTranslation(["sidebar", "account", "error"]);
  const { isLandscape } = props;
  const isDesktopDevice = (isTablet && isLandscape) || isDesktop;
  const authData = localStorage.getItem(LocalStorage.Auth);

  const [user, setUser] = useState<IUser>();
  const [creditCard, setCreditCard] = useState<IInputPayment>();
  const [billingAddress, setBillingAddress] = useState<IInputBilling>();
  const [initialCreditCard, setInitialCreditCard] = useState<IInputPayment>();
  const [initialBillingAddress, setInitialBillingAddress] =
    useState<IInputBilling>();
  const [loading, setLoading] = useState(false);

  const [cardValid, setCardValid] = useState<boolean>(false);
  const [billingValid, setBillingValid] = useState<boolean>(false);
  const [isCancelVisible, setCancelVisible] = useState<boolean>(false);
  const [cardErrorMsg, setCardErrorMsg] = useState<string>();
  const [snackbar, setSnackbar] = useState<ISnackbar>({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    if (authData) {
      setLoading(true);
      const data = JSON.parse(authData);
      AccountService.getUser(data.userId).then((response) => {
        setUser(response.data);
        if (response.data.hasPayment) {
          PaymentService.get().then(
            (paymentResponse) => {
              paymentResponse.data.creditCard.number = "";
              paymentResponse.data.creditCard.cvc = "";
              setInitialCreditCard(paymentResponse.data.creditCard);
              setInitialBillingAddress(paymentResponse.data.billingAddress);
              setCreditCard(paymentResponse.data.creditCard);
              setBillingAddress(paymentResponse.data.billingAddress);
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );
        } else {
          setLoading(false);
        }
      });
    }
  }, [authData]);

  useEffect(() => {
    setCancelVisible(
      JSON.stringify(billingAddress) !==
        JSON.stringify(initialBillingAddress) ||
        JSON.stringify(creditCard) !== JSON.stringify(initialCreditCard)
    );
  }, [creditCard, billingAddress, initialCreditCard, initialBillingAddress]);

  const updatePayment = () => {
    setLoading(true);
    setSnackbar({ open: false, severity: "error" });
    PaymentService.update({
      creditCard: creditCard,
      billingAddress: billingAddress,
    }).then(
      () => {
        const formattedCreditCard = {
          ...creditCard!,
          ...{
            cvc: "",
            number: "",
            numberLastFour: String(
              creditCard?.number.substring(creditCard.number.length - 4)
            ),
          },
        }; // remove cvc and return just last 4 numbers for credit card
        setCreditCard(formattedCreditCard);
        setInitialCreditCard(formattedCreditCard);
        setInitialBillingAddress(billingAddress);
        setLoading(false);
        setCardErrorMsg("");
        setSnackbar({
          open: true,
          severity: "success",
          message: t("sensor:payment_details_saved"),
        });
        // passed update
      },
      (error) => {
        setLoading(false);
        const errorMsg = getCreditCardErrorMsg(error);
        if (errorMsg) {
          setCardErrorMsg(errorMsg);
        } else {
          setSnackbar({
            open: true,
            severity: "error",
            message: t("error:something_went_wrong_try_again_later"),
          });
        }
      }
    );
  };

  const discardChanges = () => {
    setCreditCard(initialCreditCard);
    setBillingAddress(initialBillingAddress);

    // refresh initial states because of payment and billing data mapping
    setInitialCreditCard({ ...initialCreditCard! });
    setInitialBillingAddress({ ...initialBillingAddress! });
    setCardErrorMsg("");
  };

  return (
    <div className={styles.account_details_wrapper}>
      {isDesktopDevice ? <h2>{t("sidebar:account_details")}</h2> : ""}
      {user ? (
        <div
          className={`${
            isDesktopDevice
              ? styles.account_section_wrapper
              : styles.account_mobile_section_wrapper
          }`}
        >
          <div className={styles.account_user_details}>
            <AccountDetailsCard
              firstName={user?.firstName}
              lastName={user?.lastName}
              email={user?.email}
            />
          </div>
          {user?.hasPayment && initialCreditCard && initialBillingAddress ? (
            <div className={styles.account_payment_section}>
              <PaymentDetails
                data={initialCreditCard}
                valid={setCardValid}
                updatePaymentData={setCreditCard}
              />
              <div className={styles.credit_card_error_msg}>{cardErrorMsg}</div>
              <BillingDetails
                data={initialBillingAddress}
                valid={setBillingValid}
                updateBillingData={setBillingAddress}
              />

              {isDesktopDevice ? (
                <div className={styles.link_section}>
                  <div
                    role="button"
                    className={!isCancelVisible ? styles.hidden : ""}
                    onClick={discardChanges}
                  >
                    {t("account:cancel")}
                  </div>
                  <div
                    role="button"
                    className={`${styles.bolded} ${
                      !cardValid || !billingValid ? styles.hidden : ""
                    }`}
                    onClick={updatePayment}
                  >
                    {t("account:save")}
                  </div>
                </div>
              ) : (
                <div className={styles.button_mobile_section}>
                  <button
                    className={`btn_default_rounded ${
                      !isCancelVisible ? styles.hidden : ""
                    }`}
                    onClick={discardChanges}
                  >
                    {t("account:cancel")}
                  </button>
                  <button
                    className={`btn_default_rounded ${
                      !cardValid || !billingValid ? styles.hidden : ""
                    }`}
                    onClick={updatePayment}
                  >
                    {t("account:save")}
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
      <Loading loading={loading} />
      <Snackbar
        open={snackbar.open}
        severity={snackbar.severity}
        message={snackbar.message}
      />
    </div>
  );
};

export default withOrientationChange(AccountDetails);
