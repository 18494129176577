import React, { useState, ChangeEvent } from "react";
import styles from './CreateAccount.module.scss'
import { useHistory } from "react-router-dom";
import { URLRoutes } from "../../../enums/Routes";
import { useTranslation } from 'react-i18next';
import ExistingAccount from "../../Auth/ExistingAccount/ExistingAccount";
import TextField from "../../TextField/TextField";
import validator from "validator";
import AccountService from '../../../services/Account';
import { AccountStatus } from "../../../enums/Account";

const CreateAccount = () => {
    const { t } = useTranslation(['account', 'auth']);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [showErrorMsg, setErrorMsg] = useState<string>('');
    const [isButtonDisabled, setDisabledButtonState] = useState(true); // Next button is disabled by default, enables on input change
    const history = useHistory();

    const redirect = async () => {
        const {data} = await AccountService.validateEmail({email: email})
        if (data.valid) {
            if (email && validator.isEmail(email)) {
                history.push(URLRoutes.CreatePassword, { email: email, firstName: firstName, lastName: lastName })
                setEmail('');
            } else {
                setErrorMsg(t('account:email_not_valid'))
            }
        } else {
            // Is user tries to to register with an inactive account, show an error message under the email input with the message
            if (data.accountStatus === AccountStatus.PendingActivation) {
                setErrorMsg(t('auth:email_is_already_registered'));
            } else {
                setErrorMsg(t('account:email_is_already_registered'));
            }
            setDisabledButtonState(true);
        }
    }

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value); 
        setDisabledButtonState(!value || !validator.isEmail(value) || !firstName || !lastName);
        setErrorMsg('');
    }

    const onFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
        return setDisabledButtonState(!email || !validator.isEmail(email) || !e.target.value || !lastName)
    }

    const onLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value)
        return setDisabledButtonState(!email || !validator.isEmail(email) || !firstName || !e.target.value)
    }

    return <div className={styles.layout_wrapper}>
        <div className={styles.create_account_wrapper}>
        <h3>{t('account:create_your_account')}!</h3>
        <form autoComplete="off">
            <div className={`${email !== undefined && !validator.isEmail(email) ? styles.created_account_invalid_email : ''}`}>
                <TextField errorMsg={showErrorMsg} placeholder={t('account:enter_your_email')} type='text' change={(e: ChangeEvent<HTMLInputElement>) => onEmailChange(e)}/>
            </div>
            <TextField placeholder={t('account:first_name')} type='text' change={(e: ChangeEvent<HTMLInputElement>) => onFirstNameChange(e)}/>
            <TextField placeholder={t('account:last_name')} type='text' change={(e: ChangeEvent<HTMLInputElement>) => onLastNameChange(e)}/>
        </form>
        <button onClick={redirect} className={`${isButtonDisabled ? 'btn_default_rounded disabled' : 'btn_default_rounded'}`}>{t('account:next')}</button>
        <ExistingAccount/>
        </div>
    </div>
}

export default CreateAccount;