import axios from './config/axios';
import { LocalStorage } from '../enums/LocalStorage';
import { URLRoutes } from '../enums/Routes';
import jwt_decode from "jwt-decode";
import moment from 'moment';

interface ILogin {
    username: string,
    password: string
}
interface ISignUp {
    email: string,
    password: string
}

interface IDecodedToken {
    sub: string,
    iat: number,
    exp: number
}

class AuthService {
    isLoggedIn = false;

    login = async (loginData: ILogin) => {
        return axios.post('/login', loginData).then(data => {
            localStorage.setItem(LocalStorage.Auth, JSON.stringify(data.data));
            this.isLoggedIn = true;
            return data;
        });
    };

    logout = () => {
        localStorage.removeItem(LocalStorage.Auth);
        window.location.href = URLRoutes.Home;
        this.isLoggedIn = false;
    }

    signUp = async (signUpData: ISignUp) =>  {
        return axios.post('/user', signUpData);
    }

    getResetPasswordToken = (params = {}) => {
        return axios.get('/login/reset', {params});
    }

    checkResetPasswordToken = (params = {}) => {
        return axios.post('/login/reset', params);
    }

    saveNewPassword = (params = {}) => {
        return axios.post('/login/reset-password', params); 
    }

    activateEmail = (params = {}) => {
        return axios.post('/user/activate-email', params); 
    }

    resendActivationEmail = (params = {}) => {
        return axios.get('user/resend-activation-email', { params });
    }
    
    checkAccessToken() {
        const auth = localStorage.getItem(LocalStorage.Auth);

        if (auth) {
            const data = JSON.parse(auth);
            const decoded: IDecodedToken = jwt_decode(data.accessToken);

            const endTime = moment(new Date(decoded.exp * 1000));
            const startTime = moment();
            const expireOn = moment.duration(endTime.diff(startTime));
            const timeout = Math.floor(expireOn.asMilliseconds());

            if (timeout < 30000) {
                this.logout();
            }
        }
    }
}

export default new AuthService();