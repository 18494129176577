import React from 'react';
import styles from './PrivacyPolicy.module.scss';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t } = useTranslation(['home']);

    return <div className={styles.privacy_policy_wrapper}>
                <iframe title={t('home:privacy_policy')} src="https://www.biomechgolf.com/privacy-putt" />
            </div>
}

export default PrivacyPolicy;