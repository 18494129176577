import React, {useState} from 'react';
import styles from './Sidebar.module.scss'
import { useTranslation } from 'react-i18next';
import SidebarItem from './SidebarItem/SidebarItem';
import { ISidebar, IOrientation } from '../../interface/Sidebar';
import { URLRoutes } from '../../enums/Routes';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { withOrientationChange, isTablet, isDesktop } from 'react-device-detect';

interface ISidebarMenu {
    menu: Array<ISidebar>
}

const Sidebar = (orientation: IOrientation) => {
    const { isLandscape } = orientation;
    const history = useHistory();
    const { t } = useTranslation(['sidebar']);
    const location = useLocation();
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;

    const [sidebar, setMenuState] = useState<ISidebarMenu>({
        menu: [
            { label: t('sidebar:dashboard'), icon: 'icon_home', active: true, url: [URLRoutes.Dashboard, URLRoutes.PlanDetails]},
            { label: t('sidebar:sensor'), icon: 'icon_sensor', active: false, url: [URLRoutes.RegisterSensor, URLRoutes.Payment] },
            { label: isDesktopDevice ? t('sidebar:account_details') : t('sidebar:account'), icon: 'icon_settings', active: false, url: [URLRoutes.AccountDetails] },
        ],
    });

    const selectMenuItem = (item: ISidebar, index: number) => {
        setMenuState({
            menu: sidebar.menu.map((menu, indexItem) => ({
              ...menu,
              active: index === indexItem && item.label === menu.label
            }))
        });

        if (item.url) {
            history.push(item.url[0]);
        }
    }

    return <div className={`${isDesktopDevice ? styles.sidebar_wrapper : styles.sidebar_wrapper_mobile}`}>
                <div className={styles.sidebar_menu}>
                    {
                        sidebar.menu.map((item, index) => {
                            return <SidebarItem key={index} label={item.label} icon={item.icon} active={item.url ? item.url.includes(location.pathname) : false} click={() => selectMenuItem(item, index)} />
                        })
                    }
                </div>
           </div> 
}

export default withOrientationChange(Sidebar);

