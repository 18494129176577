import React, { useState, useEffect, CSSProperties } from "react";
import styles from './Menu.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { LocalStorage } from "../../enums/LocalStorage";
import { URLRoutes } from "../../enums/Routes";
import { useTranslation } from "react-i18next";
import { IMenu, IMenuItem } from "../../interface/Sidebar";
import { useLocation } from "react-router-dom";

const Menu = (props: IMenu) => {
    const authData = localStorage.getItem(LocalStorage.Auth);
    const [menu, setMenuState] = useState<IMenuItem[]>([]);
    const { t } = useTranslation(['header', 'sidebar']);
    const location = useLocation();

    const conditionalStyling = {
        position: 'fixed',
        left: props.open ? 0: '-100%',
        top: 0,
        height: '100%',
        width: '100%',
        transition: 'left 300ms ease'
    };

    useEffect(() => {
        setMenuState([
            !authData ? { label: t('header:log_in'), link: URLRoutes.Login } : { label: t('sidebar:dashboard'), link: URLRoutes.Dashboard },
            { label: t('header:help_center'), link: URLRoutes.HelpCenter },
            { label: t('header:privacy'), link: URLRoutes.Privacy },
            { label: t('header:terms'), link: URLRoutes.Terms },
            !authData ? { label: t('header:create_account'), link: URLRoutes.CreateAccount } : { label: t('header:sign_out'), link: URLRoutes.Home },
        ]);


    }, [authData, t]);

    return (
        <div style={conditionalStyling as CSSProperties} className={styles.menu_wrapper}>
            <div className={styles.menu_header}>
                <p> { t('header:menu') } </p>
                <CloseIcon onClick={props.click}/>
            </div>
            <ul className={styles.menu_items}>
                {
                    menu.map((item, index) => {
                        return <li onClick={() => props.redirect(item)} className={styles.menu_item} key={index}>
                                    <p className={ item.link === location.pathname ? `${styles.active_item}` : ''}>{ item.label }</p>
                                    <ChevronRightIcon />
                                </li>
                    })
                }
            </ul>
        </div>
    );
}

export default Menu;