import React, { useEffect, useState } from 'react';
import { isDesktop, isTablet, withOrientationChange } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import BillingDetails from '../../components/Payment/BillingDetails/BillingDetails';
import PaymentCard from '../../components/Payment/PaymentCard/PaymentCard';
import PaymentDetails from '../../components/Payment/PaymentDetails/PaymentDetails';
import { IInputBilling, IInputPayment } from '../../interface/Sensor';
import { IOrientation } from '../../interface/Sidebar';
import styles from './Payment.module.scss'
import SensorService from '../../services/Sensor';
import { LocalStorage } from '../../enums/LocalStorage';
import AccountService from '../../services/Account';
import PaymentService from '../../services/Payment';
import Loading from '../../components/Loading/Loading';
import { getCreditCardErrorMsg } from '../../helpers/ErrorHelper';
import { ISnackbar } from '../../interface/Snackbar';
import Snackbar from '../../components/Snackbar/Snackbar';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';

interface IPayment extends IOrientation {
    onClickSubmit?: (event: React.MouseEvent) => void,
    sensorId?: string,
    updateActivation?: (update: boolean) => void,
    updateSensorValues?: (name: string, expiresOn: string, billingDate: string) => void,
}

const Payment = (props: IPayment) => {
    const { t } = useTranslation(['sensor']);
    const { isLandscape } = props;
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;

    const [paymentData, setPaymentData] = useState<IInputPayment>();
    const [billingData, setBillingData] = useState<IInputBilling>();
    const [cardErrorMsg, setCardErrorMsg] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<ISnackbar>({open: false, severity: 'success', message: ''});

    const [isPaymentValid, setPaymentValidation] = useState<Boolean>(false);
    const [isBillingValid, setBillingValidation] = useState<Boolean>(false);

    const [planId, setPlanId] = useState<string>();
    const [isTrial, setTrialChecking] = useState<boolean>(false);
    const [activeButton, setButtonActivity] = useState<Boolean>(false);
    const authData = localStorage.getItem(LocalStorage.Auth);
    let location = useLocation<{sensorId: string}>();

    useEffect(() => {
        setButtonActivity(isPaymentValid && isBillingValid)
    }, [isPaymentValid, isBillingValid]);

    useEffect(() => {
        if (authData) {
            const data = JSON.parse(authData);
            AccountService.getUser(data.userId).then(response => {
                if (response.data.hasPayment) {
                    setLoading(true);
                    PaymentService.get().then(paymentResponse => {
                        setPaymentData(paymentResponse.data.creditCard);
                        setBillingData(paymentResponse.data.billingAddress);
                        setLoading(false);
                    }, () => {
                        setLoading(false);
                    });
                }
            });
        }
    }, [authData]);

    const submit = async () => {
        const parameters = {
            sensorId: location.state && location.state.sensorId ? location.state.sensorId : props.sensorId,
            planId: planId,
            creditCard: paymentData,
            billingAddress: billingData
        }

        try {
            setLoading(true);
            const response = await SensorService.registerSensor(parameters);

            if (props.updateSensorValues) {
                props.updateSensorValues(response.data.sensorName, response.data.expiresOn, response.data.billingDate);
            }

            if (props.updateActivation) {
                props.updateActivation(true);
            }
            setLoading(false);
        } catch (err) {
            const error = err as AxiosError;
            setLoading(false);

            const errorMsg = getCreditCardErrorMsg(error);
            if (errorMsg) {
                setCardErrorMsg(errorMsg);
            } else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: t('error:something_went_wrong_try_again_later')
                });
            }
        }
    }

    return <div className={isDesktopDevice ? styles.sensor_wrapper : styles.sensor_wrapper_mobile}>
        <div className={styles.cards_wrapper_all_cards}>
            <div className={styles.payment_card_section}>
                <PaymentCard sensorId={location.state && location.state.sensorId ? location.state.sensorId : props.sensorId} updatePlanId={setPlanId} checkTrial={setTrialChecking}/>
            </div>
            <div className={styles.form_section}>
                <PaymentDetails data={paymentData} updatePaymentData={setPaymentData} valid={setPaymentValidation}/>
                <div className={styles.credit_card_error_msg}>{cardErrorMsg}</div>
                <BillingDetails data={billingData} updateBillingData={setBillingData} valid={setBillingValidation} defaultCountry={true} />
                <div className={styles.billing_details_button_section}>
                    <button onClick={submit} className={`${styles.start_free_trial_btn} btn_default_rounded ${!activeButton ? 'disabled' : ''}`}>{isTrial ? t('sensor:submit_and_start_free_trial') : t('auth:submit')}</button>
                </div>
            </div>
        </div>
        <Loading loading={loading} />
        <Snackbar open={snackbar.open} severity={snackbar.severity} message={snackbar.message}/>
        </div>
}

export default withOrientationChange(Payment);