import React from 'react';
import styles from './HomeMeasure.module.scss'
import golfer from '../../../assets/images/golfer.png';
import DescriptionArea from '../../DescriptionArea/DescriptionArea';
import { useTranslation } from 'react-i18next';
import { isDesktop, isTablet, withOrientationChange } from 'react-device-detect';
import image_heath from "../../../assets/images/image_heath.jpg";
import image_putt_mobile from "../../../assets/images/image_putt_mobile.png";
import { IOrientation } from '../../../interface/Sidebar';

const HomeMeasure = (props: IOrientation) => {
    const { t } = useTranslation(['home']);
    const { isLandscape } = props;
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;

    return isDesktopDevice ? (
        <div className={styles.home_measure_wraper}>
            <div className={styles.home_image_section}>
                <img src={golfer} alt={t('home:measure_real_time_v2')}/>
            </div>
            <div className={styles.home_measure_content}>
                <DescriptionArea subContent={t('home:combines_with_the_putt_sensor')} title={t('home:measure_real_time')}/>
            </div>
        </div>
    ) : (
        <div className={styles.home_measure_wrapper_mobile}>
            <div className={styles.desc_area_container}>
                <DescriptionArea center={true} subContent={t('home:combines_with_the_putt_sensor')} title={t('home:measure_real_time')}/>
            </div>
            <div className={styles.heath_image_container}>
                <img className={styles.heath_image} src={image_heath} alt=''/>
            </div>
            <div className={styles.home_measure_image}>
                <img className={styles.putt_image} src={image_putt_mobile} alt=''/>
            </div>
        </div>
    )
}

export default withOrientationChange(HomeMeasure);