import { AxiosError } from "axios";
import i18n from 'i18next';
import { ResponseCode, StatusCode } from "../enums/Auth";

const getCreditCardError = (error: AxiosError) => {
    if (error.response?.data.statusCode === StatusCode.UnprocessableEntity) {
        if (error.response.data.message[0].creditCard && Array.isArray(error.response.data.message[0].creditCard.children) && error.response.data.message[0].creditCard.children.length > 0) {
            const firstProperty = Object.keys(error.response.data.message[0].creditCard.children[0])[0];
            return error.response.data.message[0].creditCard.children[0][firstProperty].errors[0].message;
        }
    }
    return '';
}

export const getCreditCardErrorMsg = (error: AxiosError) => {
    let errorMsg = '';
    errorMsg = getCreditCardError(error);

    if (errorMsg) {
        return errorMsg;
    } else if (error?.response?.data.statusCode === StatusCode.UnprocessableEntity) {
        switch(error.response.data.code) {
            case ResponseCode.InvalidCreditCardData: {
                errorMsg = i18n.t('error:invalid_credit_card_data');
                break;
            }
            case ResponseCode.CreditCardExpired: {
                errorMsg = i18n.t('error:credit_card_expired');
                break;
            }
            default: {
                errorMsg = ''
            }
        }
        return errorMsg;
    }

    return errorMsg;
}