import React from 'react';
import styles from './DescriptionArea.module.scss'
import { Trans } from 'react-i18next';

interface IDescriptionArea {
    subContent: string,
    title: string,
    center?: boolean
}

const DescriptionArea = (props: IDescriptionArea) => {
    return <div className={`${styles.description_content} ${props.center ? styles.center : ''}`}>
                <h2>
                    <Trans defaults={props.title} components={{bold: <span /> }}/>
                </h2>
                <p>{props.subContent}</p>
            </div>
}

export default DescriptionArea;