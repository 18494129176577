import React, {useEffect, useState} from "react";
import styles from './NewSensor.module.scss'
import { useTranslation } from 'react-i18next';
import back_of_sensor from '../../../assets/images/back_of_sensor.png';
import TextField from "../../../components/TextField/TextField";
import SensorService from '../../../services/Sensor';
import { withOrientationChange, isTablet, isDesktop } from 'react-device-detect';
import { IOrientation } from "../../../interface/Sidebar";

interface IInputItem {
    placeholder: string;
    type: string;
    inputCssLength: boolean;
    maxLength: number,
    value: string|null;
}

interface INewSensor extends IOrientation {
  onSensorUpdate: (id: string) => void
}

const NewSensor = (props: INewSensor)  => {
  const { isLandscape } = props;
  const isDesktopDevice = (isTablet && isLandscape) || isDesktop;
    const { t } = useTranslation(['sensor']);
    const listOfInputs = [];
    for (let i=0; i<6; i++){
        listOfInputs.push( { placeholder: t('sensor:zeros'), type: 'text', inputCssLength: true, maxLength: 2, value: null });
    }
    const [input, setInputState] = useState<IInputItem[]>(listOfInputs);
    const [showErrorMsg, setErrorMsg] = useState<string>('');
    const [isButtonDisabled, setDisabledButtonState] = useState(true); // Register button is disabled by default, enables on input change

    useEffect(() => {
      // part of code (validation) that should be executed at input state change
      const macValues: Array<string|null> = [];

      input.forEach(macInput => {
          macValues.push(macInput.value)
      });

      const notValidEntry = macValues.some(v => v?.length !== 2);
      setDisabledButtonState(notValidEntry);
    }, [input]);

    const setInputValue = (value: string, arrayIndex: number) => {
        const updatedInputs = input.map((item, index) => {
          if (index === arrayIndex) {
            return {
              ...item,
              value: index === arrayIndex ? value : null
            };
          } else {
            return item;
          }
        });
        setInputState(updatedInputs);
      };

    const registerSensor = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const macAddress = input[0].value + ":" + input[1].value + ":" + input[2].value + ":" + input[3].value + ":" + input[4].value + ":"+ input[5].value;
        const regexp = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i;
        if (regexp.test(macAddress)) {
            setErrorMsg('')

            SensorService.validate({id: macAddress}).then(async response => {
              // Checks if the supplied sensor exists, doesn’t belong to an active subscription.
              if (response.data.valid) {
                props.onSensorUpdate(macAddress);
              } else {
                setErrorMsg(t('sensor:number_not_valid'))
              }
            });

        } else {
            setErrorMsg(t('sensor:number_not_valid'))
        }
    }

    const onMacAddressChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setInputValue(e.target.value, index);
        onFocus(e);
        setErrorMsg(''); // If the user changes the sensor id that generated the errror, the error should hide
    }
    
    const onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("_");

        if (value.length >= maxLength) {
            // Check if it's not the last input field
            if (parseInt(fieldIndex, 10) < 6) {
              // Get the next input field
              const nextSibling = document.querySelector(
                `input[name=${fieldName}_${parseInt(fieldIndex, 10) + 1}]`
              );
              // If found, focus the next field
              if (nextSibling !== null) {
                (nextSibling as HTMLElement)?.focus();
              }
            }
          }
    }

    return <div className={`${styles.new_sensor_wrapper} ${isDesktopDevice ? '' : styles.new_sensor_wrapper_mobile}`}>
        <div className={styles.inputs_section}>
            <h2 className={styles.new_sensor_title}>{t('sensor:register_new_sensor')}</h2>
            <p className={styles.new_sensor_subtitle} >{t('sensor:add_digit_number_of_sensor')}</p>
            <div className={styles.ns_inputs_section}>
                {
                    input.map((item, index) => {
                       return <TextField pattern="[a-fA-F0-9]" fieldName={'sen_'+index} key={index} placeholder={item.placeholder} type={item.type}  maxLength ={item.maxLength} change={(e: React.ChangeEvent<HTMLInputElement>) => onMacAddressChange(e, index)}/>
                    })
                }
                <div className={styles.error_msg}>{showErrorMsg}</div>
            </div>

            <div className={`${isDesktopDevice ? styles.hidden : styles.new_sensor_picture_section}`} >
              <img src={back_of_sensor} alt={back_of_sensor}/>
            </div>

            <button onClick={(e) => registerSensor(e)} className={`${isButtonDisabled ? 'btn_default_rounded disabled' : 'btn_default_rounded'}`}>{t('sensor:register')}</button>
        </div>
        <div className={`${isDesktopDevice ? styles.new_sensor_picture_section : styles.hidden}`}>
            <img src={back_of_sensor} alt={back_of_sensor}/>
        </div>
    </div>
}

export default withOrientationChange(NewSensor);