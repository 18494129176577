import { IInputBilling, IInputPayment } from "../interface/Sensor";
import axios from "./config/axios";

interface IPayment {
  creditCard?: IInputPayment;
  billingAddress?: IInputBilling;
}

class PaymentService {
  get = () => {
    return axios.get("/user/payment");
  };

  update = (params: IPayment) => {
    return axios.post("/user/payment", params);
  };

  listOfPlans = (params: { sensorId: string }) => {
    return axios.get("/activation/plans", { params });
  };

  countryList = () => {
    return axios.get("/country");
  };
}

export default new PaymentService();
