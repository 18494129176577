import React from 'react';
import styles from './SidebarItem.module.scss'
import { ISidebar } from '../../../interface/Sidebar';
import { withOrientationChange, isTablet, isDesktop } from 'react-device-detect';

interface IOrientation extends ISidebar {
    isLandscape?: boolean,
    isPortrait?: boolean
}
const SidebarItem = (props: IOrientation ) => {
    const { isLandscape } = props;
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;
    return <div onClick={props.click} className={`${styles.sidebar_item_wrapper} ${isDesktopDevice ? '' : styles.sidebar_item_wrapper_mobile} ${props.active ? styles.active : ''}`}>
        <span className={styles.icon_wrapper}>
            <span className={props.icon + " icon_svg"}></span>
        </span>
        <span className={styles.sidebar_item}>{props.label}</span>
    </div>
}

export default withOrientationChange(SidebarItem); 