import React, { useState, useEffect } from "react";
import styles from "./BillingDetails.module.scss";
import { useTranslation } from "react-i18next";
import TextField from "../../TextField/TextField";
import { IInputBilling, IInputBillingState } from "../../../interface/Sensor";
import { IOrientation } from "../../../interface/Sidebar";
import {
  withOrientationChange,
  isTablet,
  isDesktop,
} from "react-device-detect";
import { MenuItem, Select } from "@material-ui/core";
import { Country } from "../../../enums/Sensor";
import Payment from "../../../services/Payment";

interface IBillingtDetails extends IOrientation {
  updateBillingData?: (arg0: IInputBilling) => void;
  valid: (value: boolean) => void;
  data?: IInputBilling;
  defaultCountry?: boolean;
}

interface ICountryList {
  code: string;
  name: string;
}

const BillingDetails = (props: IBillingtDetails) => {
  const { t } = useTranslation(["sensor"]);
  const { isLandscape } = props;
  const isDesktopDevice = (isTablet && isLandscape) || isDesktop;
  const [countryList, setCountryList] = useState<ICountryList[]>([]);
  const [input, setInputState] = useState<IInputBilling>({
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const [field, setFieldState] = useState<IInputBillingState>({
    address: false,
    city: false,
    state: false,
    zipCode: false,
  });

  const onInputsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputState({
      ...input,
      [name]: value,
    });
    setFieldState({
      ...field,
      [e.currentTarget.name]: String(e.currentTarget.value).length === 0,
    });
  };

  useEffect(() => {
    Payment.countryList().then(({ data }) => {
      setCountryList(data);
    });
  }, []);

  useEffect(() => {
    if (props.data) {
      if (props.defaultCountry && !props.data.country) {
        props.data.country = Country.USA;
      } // if default country is set to true and county is empty, set USA as default country
      setInputState(props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    if (props.updateBillingData) {
      props.updateBillingData(input);
    }

    checkValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const checkValidation = () => {
    props.valid(
      input.address && input.city && input.state && input.zipCode ? true : false
    );
  };

  return (
    <div
      className={`${
        isDesktopDevice
          ? styles.billing_details_wrapper
          : styles.billing_mobile_details_wrapper
      }`}
    >
      <h2 className={styles.billing_details_title}>
        {t("sensor:billing_address")}
      </h2>

      <div className={styles.billing_details_address}>
        <p className={styles.billing_details_input_title}>
          {t("sensor:billing_address")}
        </p>
        <TextField
          value={input.address}
          required={field.address}
          maxLength={50}
          type="text"
          change={onInputsChange}
          fieldName="address"
        />
      </div>
      <div className={styles.billing_details_city}>
        <p className={styles.billing_details_input_title}>{t("sensor:city")}</p>
        <TextField
          value={input.city}
          required={field.city}
          maxLength={50}
          type="text"
          change={onInputsChange}
          fieldName="city"
        />
      </div>

      <div className={styles.billing_details_state_zip_section}>
        <div className={styles.billing_details_state}>
          <p className={styles.billing_details_input_title}>
            {t("sensor:state")}
          </p>
          <TextField
            value={input.state}
            type="text"
            required={field.city}
            maxLength={50}
            change={onInputsChange}
            fieldName="state"
          />
        </div>
        <div className={styles.billing_details_zip_code}>
          <p className={styles.billing_details_input_title}>
            {t("sensor:zip_code")}
          </p>
          <TextField
            value={input.zipCode}
            type="text"
            required={field.zipCode}
            maxLength={9}
            change={onInputsChange}
            fieldName="zipCode"
          />
        </div>
      </div>

      <div className={styles.billing_details_country}>
        <p className={styles.billing_details_input_title}>
          {t("sensor:country")}
        </p>
        <Select
          className={styles.country_select}
          value={input.country ? input.country : ""}
          name="country"
          onChange={(e) => {
            setInputState({
              ...input,
              [e.target.name as string]: e.target.value,
            });
          }}
        >
          {countryList.map(({ code, name }, index) => (
            <MenuItem value={code} key={index}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default withOrientationChange(BillingDetails);
