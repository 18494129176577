import React, { useEffect, useState } from "react";
import styles from './Dashboard.module.scss'
import { useTranslation } from 'react-i18next';
import RegisterSensor from "../../../components/Sensor/RegisterSensor/RegisterSensor";
import { StatusState } from "../../../enums/Sensor";
import Sensor from '../../../services/Sensor';
import { isTablet, withOrientationChange, isDesktop } from "react-device-detect";
import { IOrientation } from "../../../interface/Sidebar";
// import { ISnackbar } from "../../../interface/Snackbar";
// import Snackbar from "../../../components/Snackbar/Snackbar";
import Loading from "../../../components/Loading/Loading";

interface ISensors {
    status: number,
    sensorName?: string,
    id: string
    sensorId: string,
    planId: string,
    isCanceled: boolean,
    expiresOn: string,
    billingDate: string,
    price: string
    nextPlanId: string,
    isPaid: boolean
}

const Dashboard = (props: IOrientation) => {
    const { t } = useTranslation(['sidebar']);
    const [sensors, setSensors] = useState<ISensors[]>([]);
    const { isLandscape } = props;
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;
    // const [snackbar, setSnackbar] = useState<ISnackbar>({open: false, severity: 'success', message: 'You’ve successfully registered your sensor!'}); Commented until functionality task is ready
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const defaultSortingParametres = {
            _order_by: 'created_on desc'
        }; 
        setLoading(true);
        Sensor.getSensors(defaultSortingParametres).then(response => {
            const sensorData = response.data;
            if (sensorData.length > 0) {
                setSensors(sensorData);
            }
            setLoading(false);
        });
    }, [t]);

    return <div className={`${isDesktopDevice ? styles.dashboard_wrapper : styles.dashboard_wrapper_mobile}`}>
        { isDesktopDevice ? <h2>{t('sidebar:dashboard')}</h2> : '' }
        <div className={styles.sensors_wrapper}>
            {
                sensors.length > 0 ?
                sensors.map((item, index) => {
                    return <RegisterSensor key={index} id={item.id} planId={item.planId} name={item.sensorName} state={item.status}  sensorId={item.sensorId} isCanceled={item.isCanceled} expiresOn={item.expiresOn} billingDate={item.billingDate} price={item.price} nextPlanId={item.nextPlanId} isPaid={item.isPaid}/>
                }) :
                <RegisterSensor state={StatusState.Default}/>
            }
        </div>
        <Loading loading={loading} />
        {/* <Snackbar open={true} severity={snackbar.severity} message={snackbar.message}/> */}
    </div>
}

export default withOrientationChange(Dashboard);