import React, { FormEvent, useEffect, useState } from "react";
import styles from './CreditCardField.module.scss'

interface INumberField {
    placeholder: string,
    required?: boolean,
    name: string
    onInputUpdate?: (value: number|string, name: string) => void,
    value?: number
}

const CreditCardField = (props: INumberField) => {
    const [value, setValue] = useState<number|string>();

    const handleChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const cardValue = target.value ? target.value.replace(/\D/g, "") : '';

        if (props.onInputUpdate) {
            props.onInputUpdate(cardValue, target.name);
        }

        setValue(formatCardNumber(cardValue));
    }

    const formatCardNumber = (value: string) => {
        switch(true) {
            case Number(value.charAt(0)) === 4: {
                // Visa, Visa Electron
                return value.match(/.{1,4}/g)?.join(' ');
            }
            case Number(value.substring(0, 2)) === 34 || Number(value.substring(0, 2)) === 37: {
                // American Express	
                return formatCardNumberByPattern(value, [4, 6, 5]);
            }
            case Number(value.substring(0, 2)) === 62: {
                // China UnionPay
                return value.length <= 16 ? value.match(/.{1,4}/g)?.join(' ') : formatCardNumberByPattern(value, [6, 13]);
            }
            case (Number(value.substring(0, 2)) >= 51 && Number(value.substring(0, 2)) <= 55) || (Number(value.substring(0, 6)) >= 222100 && Number(value.substring(0, 6)) <= 272099): {
                // MasterCard + Diners Club United States & Canada 54, 55
                return value.match(/.{1,4}/g)?.join(' ');
            }
            case ((Number(value.substring(0, 6)) >= 500000 && Number(value.substring(0, 6)) <= 509999) || (Number(value.substring(0, 6)) >= 560000 && Number(value.substring(0, 6)) <= 589999) || (Number(value.substring(0, 6)) >= 600000 && Number(value.substring(0, 6)) <= 699999)): {
                // Maestro + Dankort 5019, InterPayment 636
                if (value.length <= 13) {
                    return formatCardNumberByPattern(value, [4, 4, 5]);
                } else if (value.length <= 15) {
                    return formatCardNumberByPattern(value, [4, 6, 5]);
                } else if (value.length === 16) {
                    return value.match(/.{1,4}/g)?.join(' ');
                } else {
                    return formatCardNumberByPattern(value, [4, 4, 4, 4, 3]);
                }
            }
            case (Number(value.substring(0, 3)) >= 300 && Number(value.substring(0, 3)) <= 305) || Number(value.substring(0, 3)) === 309 || Number(value.substring(0, 2)) === 36 || Number(value.substring(0, 2)) === 38 || Number(value.substring(0, 2)) === 39: {
                // Diners Club Carte Blanche, Diners Club International
                return formatCardNumberByPattern(value, [4, 6, 4]);
            }
            case Number(value.substring(0, 4)) === 6011 || (Number(value.substring(0, 6)) >= 622126 && Number(value.substring(0, 6)) <= 622925) || (Number(value.substring(0, 3)) >= 644 && Number(value.substring(0, 3)) <= 649) || Number(value.substring(0, 2)) === 65: {
                // Discover
                return value.match(/.{1,4}/g)?.join(' ');
            }
            case Number(value.substring(0, 4)) >= 3528 && Number(value.substring(0, 4)) <= 3589: {
                // JCB
                return value.match(/.{1,4}/g)?.join(' ');
            }
            case Number(value.charAt(0)) === 1: {
                // UATP
                return formatCardNumberByPattern(value, [4, 5, 6]);
            }
            default:
                return value;
        }
    }

    const formatCardNumberByPattern = (value: string, patternValues: number[]) => {
        let stringValue = "";
        let sum = 0;

        for(let i = 0; i < patternValues.length; i++) {
            if (i === 0) {
                stringValue += value.substring(0, patternValues[i]);
            } else if (value.substring(sum, sum + patternValues[i])) {
                stringValue += " " + value.substring(sum, sum + patternValues[i]);
            }

            sum += patternValues[i];
            if (i === patternValues.length - 1 && value.length > sum) {
                stringValue += " " + value.substring(sum);
            }
        }

        return stringValue;
    }

    useEffect(() => {
        if (!props.value) {
            setValue('');
        }
    }, [props.value]);

    return (
        <input type="text" required={props.required} maxLength={50} name={props.name} placeholder={props.placeholder} onChange={handleChange} value={value ? value : ''} className={styles.credit_card_field}/>
    );
}

export default CreditCardField;