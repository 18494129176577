/**
 *  Validator maxLength will be added to configuration and default to isStrongPassword, until then check manually
 *  minUppercase = 1, minNumbers = 1 are default values
 *  Requirements: min = 1, max = 50, digit = 1, uppercase = 1
 */

import validator from "validator";

export const isStrongPassword = (value?: string) => {
    return value && value.length < 50 && validator.isStrongPassword(value, {minLength: 5, minSymbols: 0});
}