import React, { useState, useEffect } from "react";
import styles from './PlanDetails.module.scss'
import { Link, useLocation, useHistory } from "react-router-dom";
import { URLRoutes } from "../../../enums/Routes";
import { useTranslation } from "react-i18next";
import PlanDialog from "../../Dialog/PlanDialog/PlanDialog";
import { ISnackbar } from "../../../interface/Snackbar";
import Snackbar from "../../../components/Snackbar/Snackbar";
import SensorService from "../../../services/Sensor";
import PaymentService from "../../../services/Payment";
import moment from 'moment';
import { StatusState } from "../../../enums/Sensor";
import { Subscription } from "../../../enums/Sensor";
import DialogComponent from "../../Dialog/Dialog";
import { IPlanDetails } from "../../../interface/Sensor";

interface IPlanDetailsState {
    planDetails: {
        sensorName: string,
        name?: string,
        id: string,
        planId: string,
        status: number,
        sensorId: string,
        isCanceled: boolean
        expiresOn?: string,
        billingDate?: string,
        price?: string,
        state?: number,
        nextPlanId?: string
    },
    pendingSwitch?: boolean
}
const PlanDetails = () => {
    const { t } = useTranslation(['sensor']);
    const [openedCancelDialog, setCancelDialogOpen] = useState(false);
    const [planId, setPlanId] = useState<string>('');
    const [monthlyPrice, setMonthlyPrice] = useState<string>('');
    const [yearlyPercentage, setYearlyPercentage] = useState<number>();
    const [nextPlanPrice, setNextPlanPrice] = useState<string>('');

    const [openedUpgradeDialog, setUpgradeDialogOpen] = useState(false);
    const [openedErrorResumeDialog, setErrorResumeDialog] = useState(false);
    const [openedResumeDialog, setResumeDialogOpen] = useState(false);
    const [snackbar, setSnackbar] = useState<ISnackbar>({open: false, severity: 'success', message: ''});
    const {state} = useLocation<IPlanDetailsState>();
    const history = useHistory();

    const cancel = () => {
        SensorService.cancelPlan(state.planDetails.id).then(response => {
            setCancelDialogOpen(false);
            setSnackbar({
                open: true,
                severity: 'success',
                message: t('sensor:you_have_canceled_your_plan')
            })
            snackbarAutoHide();

            setTimeout(() => {
                history.push(URLRoutes.Dashboard);
            }, 3000); // Delay until snackbar is displayed
        }).catch(error =>{
            setCancelDialogOpen(false);
            setSnackbar({
                open: true,
                severity: 'error',
                message: t('sensor:something_went_wrong')
            })
            snackbarAutoHide();
        });
    }

    useEffect(() => {
        PaymentService.listOfPlans({sensorId: state.planDetails.sensorId}).then(response => {
            const listOfPlans = response.data;
            
            for (let i = 0; i < listOfPlans.length; i++) {
                if (!state.planDetails.planId.includes(listOfPlans[i].id)) {
                    setPlanId(listOfPlans[i].id);

                    // Price per month
                    if (listOfPlans[i].id.includes(t('sensor:month'))) {
                        setMonthlyPrice(listOfPlans[i].price)
                    }
                }
            }

            /**
             * Get price of Next Plan
             * **/
            if (state.planDetails.nextPlanId) {
                const nextPlan = listOfPlans.filter((item: IPlanDetails) => item.id === state.planDetails.nextPlanId)[0];
                setNextPlanPrice(nextPlan.price);
            }


            /** 
             * calculate annual percentage
             * monthlyPrice*12 : 100 = (montjhlyPrice*12 - yearlyPrice) : x
             * x = (100 x (montjhlyPrice*12 - yearlyPrice)) / (monthlyPrice*12)
             */
            const monthlyPrice = listOfPlans[0].price;
            const yearlyPrice = listOfPlans[1].price;
            const monthlyTotal = monthlyPrice * 12;
            const percentage = Math.round((100 * (monthlyTotal - yearlyPrice)) / monthlyTotal);
            setYearlyPercentage(percentage);
        });
    }, [state.planDetails.sensorId, state.planDetails.planId, t, state.planDetails.nextPlanId]);

    const changePlan = () => {
        SensorService.changePlan({id: state.planDetails.id, planId: planId}).then(response => {
            setUpgradeDialogOpen(false);
            setSnackbar({
                open: true,
                severity: 'success',
                message: t('sensor:you_have_changed_your_plan')
            });
            snackbarAutoHide();

            setTimeout(() => {
                history.push(URLRoutes.Dashboard);
            }, 3000); // Delay until snackbar is displayed
        }).catch(error =>{
            setUpgradeDialogOpen(false);
            setSnackbar({
                open: true,
                severity: 'error',
                message: t('sensor:something_went_wrong')
            })
            snackbarAutoHide();
        });
    }

    const resumeSubscription = () => {
        SensorService.resumePlan({id: state.planDetails.id}).then(response => {
            setResumeDialogOpen(false);
            setSnackbar({
                open: true,
                severity: 'success',
                message: t('sensor:you_have_resumed_your_plan')
            });
            snackbarAutoHide();

            setTimeout(() => {
                history.push(URLRoutes.Dashboard);
            }, 3000); // Delay until snackbar is displayed
        }).catch(error =>{
            const {code} = error.response.data;
            setResumeDialogOpen(false);

            // When the API returns error code 162 when trying to resume a canceled subscription, the web should display a pop-up
             if (code === Subscription.ErrorResumeCode) {
                setErrorResumeDialog(true);
                return;
             }

            setSnackbar({
                open: true,
                severity: 'error',
                message: t('sensor:something_went_wrong')
            })
            snackbarAutoHide();
        });
    }

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({open: false});
        }, 3000);
    }

    return <div className={styles.plan_details_wrapper}>
        <Link to={URLRoutes.Dashboard}><span className='icon_svg icon_arrow'></span> {t('sensor:back_to_dashboard')}</Link>
        <div className={styles.plan_section_wrapper}>
            <h2>{t('sensor:your_plan_details')}</h2>
            <div className={styles.sensor_section}>
                <p>{state.planDetails.sensorName ? state.planDetails.sensorName : state.planDetails.name}</p>
                { state.planDetails.isCanceled && state.planDetails.state === StatusState.Active ? <span className={styles.sensor_status_pending}>{t('sensor:pending_cancelation')}</span> : <span className={styles.sensor_status}>{t('sensor:active')}</span>}
            </div>
            <div className={styles.plan_section}>
                <p>{state.planDetails.planId.includes(t('sensor:month')) ? t('sensor:monthly') : t('sensor:yearly')}</p>
                <p> ${ state.planDetails.price } {state.planDetails.planId.includes(t('sensor:month')) ? t('sensor:per_month') : t('sensor:per_year')}</p>
            </div>
            {
                state.pendingSwitch ? 
                <p className={styles.billing_details}>{t('sensor:plan_ending_date', {date: moment(state.planDetails.billingDate).format('MM-DD-YYYY')})}</p> :
                <p className={styles.billing_details}>{state.planDetails.isCanceled && state.planDetails.state === StatusState.Active ? t('sensor:cancelation_date') : t('sensor:next_billing_date')}: { moment(state.planDetails.billingDate).format('MM-DD-YYYY')}</p>
            }
            {
                state.pendingSwitch && state.planDetails.nextPlanId? 
                <div className={styles.plan_pending_switch_section}>
                    <div className={styles.plan_section}>
                        <p>{state.planDetails.nextPlanId.includes(t('sensor:month')) ? t('sensor:monthly') : t('sensor:yearly')}</p>
                        <p> ${nextPlanPrice} {state.planDetails.nextPlanId.includes(t('sensor:month')) ? t('sensor:per_month') : t('sensor:per_year')}</p>
                    </div>
                    <p className={styles.billing_details}>{t('sensor:plan_starting_on', {date: moment(state.planDetails.billingDate).format('MM-DD-YYYY')})}</p>
                </div> : ''

            }
            <div className={styles.plan_button_section}>
                { state.pendingSwitch ? 
                    <p onClick={() => setCancelDialogOpen(true)}>{t('sensor:cancel_plan')}</p> :
                /* Resume Subscription / Upgrade Plan*/
                ( state.planDetails.isCanceled && state.planDetails.state === StatusState.Active  ? 
                    <button onClick={() => setResumeDialogOpen(true)} className='btn_default_rounded'>{t('sensor:resume_subscription')}</button> :
                    <div className={styles.plan_details_button_section}>
                        {yearlyPercentage || monthlyPrice ? <button onClick={() => setUpgradeDialogOpen(true)} className='btn_default_rounded'>{state.planDetails.planId.includes(t('sensor:month')) ? t('sensor:upgrade_to_yearly_plan', {percentage: yearlyPercentage}) : t('sensor:switch_to_monthly', {price: monthlyPrice})}</button> : ''}
                        {!state.planDetails.isCanceled ? <p onClick={() => setCancelDialogOpen(true)}>{t('sensor:cancel_plan')}</p> : ''}
                    </div>
                )}
            </div>
        </div>

        <PlanDialog open={openedUpgradeDialog} title={t('sensor:confirm_change_of_your_plan')} content={t('sensor:are_you_sure_you_would_like_to_change')} buttonLabel={t('sensor:change_my_plan')} 
        linkLabel={t('sensor:cancel_plan_change')} click={() => setUpgradeDialogOpen(false)} confirm={changePlan}/> {/* Change plan dialog */}
        <Snackbar open={snackbar.open} severity={snackbar.severity} message={snackbar.message}/>

        <PlanDialog open={openedCancelDialog} title={t('sensor:are_you_sure_you_would_like_to_cancel')} content={t('sensor:your_plan_will_remain_active')} buttonLabel={t('sensor:cancel_my_plan')} 
        linkLabel={t('sensor:return_to_plan_details')} click={() => setCancelDialogOpen(false)} confirm={cancel}/> {/* Cancel plan dialog */}
        <Snackbar open={snackbar.open} severity={snackbar.severity} message={snackbar.message}/>

        <PlanDialog open={openedResumeDialog} title={t('sensor:are_you_sure_you_would_like_to_resume')} buttonLabel={t('sensor:resume_my_plan')} 
        linkLabel={t('sensor:return_to_plan_details')} click={() => setResumeDialogOpen(false)} confirm={resumeSubscription}/> {/* Resume plan dialog */}
        <Snackbar open={snackbar.open} severity={snackbar.severity} message={snackbar.message}/>

        <DialogComponent open={openedErrorResumeDialog} title={''} content={t('sensor:canceled_subscription_cannot_be_resumed')} buttonLabel={t('account:ok')} click={() => setErrorResumeDialog(false)}/>
    </div>
}

export default PlanDetails;