import React, { useState, ChangeEvent, FocusEvent, useEffect } from "react";
import styles from './AccountDetailsCard.module.scss'
import { useTranslation } from "react-i18next";
import TextField from "../../TextField/TextField";
import { LocalStorage } from "../../../enums/LocalStorage";
import AccountService from '../../../services/Account';
import Snackbar from "../../Snackbar/Snackbar";
import { ISnackbar } from "../../../interface/Snackbar";
import validator from "validator";
import { isStrongPassword } from "../../Validator/PasswordValidation/PasswordValidation";
import DialogComponent from "../../Dialog/Dialog";
import { StatusCode } from "../../../enums/Auth";
import { ResponseCode } from "../../../enums/Auth";
import { AccountStatus } from "../../../enums/Account";
import { isTablet, isDesktop, withOrientationChange } from "react-device-detect";
import { IOrientation } from "../../../interface/Sidebar";
import Loading from "../../Loading/Loading";
import { AxiosError } from "axios";

interface IAccountDetails extends IOrientation {
    firstName?: string,
    lastName?: string,
    email?: string
    password?: string
}

const AccountDetailsCard = (props: IAccountDetails) => {
    const { t } = useTranslation(['sidebar', 'account']);
    const authData = localStorage.getItem(LocalStorage.Auth);
    const [snackbar, setSnackbar] = useState<ISnackbar>({open: false, severity: 'success', message: ''});
    const [userInitialData, setUserInitialData] = useState<IAccountDetails>();
    const [isSaveVisible, setSaveVisible] = useState<boolean>(false);
    const [isCancelVisible, setCancelVisible] = useState<boolean>(false);
    const [openedDialog, setDialogOpen] = useState(false);
    const { isLandscape } = props;
    const isDesktopDevice = (isTablet && isLandscape) || isDesktop;
    const [loading, setLoading] = useState(false);

    // Set account detail fields
    const [account, setAccountDetails] = useState<IAccountDetails | undefined>();
    
    // Error messages
    const [errorMessage, setErrorMessage] = useState<IAccountDetails | undefined>();

    let userId = ''; // Test for deployment pipeline

    if (authData) {
        const data = JSON.parse(authData);
        userId = data.userId;
    }

    const save = async () => {
        setSnackbar({open: false});

        const userData = {
            ...account?.email !== userInitialData?.email && {email: account?.email},
            ...account?.password !== userInitialData?.password && {password: account?.password}, // Save password that's not generated as random string
            ...account?.firstName !== userInitialData?.firstName && {firstName: account?.firstName},
            ...account?.lastName !== userInitialData?.lastName && {lastName: account?.lastName}
        };

        if (userData) {
            // If user updates email, validate new email value.
            if (userData.email) {
                const {data} = await AccountService.validateEmail({email: userData.email})
    
                if (data.accountStatus !== AccountStatus.Activated && data.valid) {
                    update(userData);
                } else {
                    emailErrorMessage();
                    errorHandler();
                }
            } else {
                update(userData);
            }
        }
    }

    const update = async (userData: IAccountDetails) => {
        setLoading(true);
        try {
            await AccountService.saveAccountData(userId, userData);
            setSnackbar({
                open: true,
                severity: 'success',
                message: t('account:account_details_saved')
            });

            if (account?.email !== userInitialData?.email) {
                setDialogOpen(true);
                // reset email to initial, because user stays on old logged email address
                setAccountDetails(prevState => ({
                    ...prevState,
                    'email': userInitialData?.email
                }));
            }
            // update initial data with new first and last name and password
            setUserInitialData(prevState => ({
                ...prevState,
                'firstName': account?.firstName,
                'lastName': account?.lastName,
                'password': account?.password
            }));
            setLoading(false);
        } catch (err) {
            const error = err as AxiosError;
            errorHandler();

            /**
             * Display error message for email being already registered or
             * for email having valid (non expired and unused) email activation token returns following response
            */
            if (error.response?.data.statusCode === StatusCode.UnprocessableEntity && error.response?.data.code === ResponseCode.EmailAlreadyTaken) {
                emailErrorMessage();
            }
            setLoading(false);
        }
    }

    const emailErrorMessage = () => {
        setErrorMessage(prevState => ({
            ...prevState,
            'email': t('account:email_is_already_registered')
        }));
    }

    const errorHandler = () => {
        setSnackbar({
            open: true,
            severity: 'error',
            message: t('account:error_saving_account_details')
        })
    }

    const generatePassword = () => {
        let pass = "";
        const capitalLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const smallLetters = "abcdefghijklmnopqrstuvwxyz";
        
        const capitalLength = (Math.random() * 7) + 2;
        const smallLength = (Math.random() * 7) + 2;

        for (let i = 0; i < capitalLength; i++) {
            pass += capitalLetters.charAt(Math.floor(Math.random() * capitalLetters.length));
        }

        for (let i = 0; i < smallLength; i++) {
            pass += smallLetters.charAt(Math.floor(Math.random() * smallLetters.length));
        }

        pass += Math.floor(Math.random() * 10); // add random integer between 0 and 9 (both included)
      
        return pass;
    }


    useEffect(() => {
        const userInitialData = {
            firstName: props.firstName,
            lastName: props.lastName,
            email: props.email,
            password: generatePassword()
        };
        setAccountDetails(userInitialData);
        setUserInitialData(userInitialData);
    },[props.firstName, props.lastName, props.email]);

    useEffect(() => {
        const errorMessage: IAccountDetails = {firstName: "", lastName: "", email: "", password: ""};
        if (account) {
            errorMessage.firstName = !account.firstName ? t('account:error:field_is_required', {field: t('account:first_name')}) : '';
            errorMessage.lastName = !account.lastName ? t('account:error:field_is_required', {field: t('account:last_name')}) : '';
            errorMessage.email = !account.email ? t('account:error:field_is_required', {field: t('account:email')}) : (!validator.isEmail(account.email) ? t('account:email_not_valid') : '');
            errorMessage.password = !account.password ? t('account:error:field_is_required', {field: t('account:password')}) : (account && !isStrongPassword(account.password) ? t('account:password_requirements') : '');
        }
       
        setErrorMessage(errorMessage);

        if (account && userInitialData) {
            setSaveVisible(!Object.values(errorMessage).some(e => e) && JSON.stringify(account) !== JSON.stringify(userInitialData)); // if no one error exists and new account data is different from initial, show the save button
            setCancelVisible(JSON.stringify(account) !== JSON.stringify(userInitialData));
        }
    }, [account, userInitialData, t]);

    const focus = (e: FocusEvent<HTMLInputElement>) => {
        const name = e.target.name;
        setAccountDetails(prevState => ({
            ...prevState,
            [name]: ''
        }));
    }

    const cancel = () => {
        setAccountDetails(userInitialData);
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setAccountDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    return <div className={`${isDesktopDevice ? styles.account_card_wrapper : styles.account_mobile_card_wrapper}`}>
                <h3>{t('account:click_on_information_to_edit')}</h3>
                <div className={styles.field_wrapper}>
                    <label>{t('account:first_name')}</label>
                    <TextField fieldName='firstName' errorMsg={errorMessage?.firstName} type='text' value={account?.firstName || ''} change={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}/>
                </div>
                <div className={styles.field_wrapper}>
                    <label>{t('account:last_name')}</label>
                    <TextField fieldName='lastName' errorMsg={errorMessage?.lastName} type='text' value={account?.lastName || ''} change={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}/>
                </div>
                <form  noValidate autoComplete="off">
                    <div className={styles.field_wrapper}>
                        <label>{t('account:email_address')}</label>
                        <TextField fieldName='email' errorMsg={errorMessage?.email} type='text' autoComplete="off" value={account?.email  || ''} change={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} />
                    </div>
                    <div className={styles.field_wrapper}>
                        <label>{t('account:password')}</label>
                        <TextField fieldName='password' errorMsg={errorMessage?.password} type='password' autoComplete="new-password" value={account?.password  || ''} change={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} 
                        focus={(e: FocusEvent<HTMLInputElement>) => focus(e)}/>
                    </div>
                </form>
                {isDesktopDevice ? 
                    <div className={styles.link_section}>
                        <div role="button" onClick={cancel} className={`${!isCancelVisible ? styles.hidden : ''}`}>{t('account:cancel')}</div>
                        <div role="button" onClick={save} className={`${styles.bolded} ${!isSaveVisible ? styles.hidden : ''}`}>{t('account:save')}</div>
                    </div>
                : 
                    <div className={styles.button_mobile_section}>
                        <button onClick={cancel} className={` btn_default_rounded ${!isCancelVisible ? styles.hidden : ''}`}>{t('account:cancel')}</button>
                        <button onClick={save} className={` btn_default_rounded ${styles.bolded} ${!isSaveVisible ? styles.hidden : ''}`}>{t('account:save')}</button>
                    </div>
                }

                <Snackbar open={snackbar.open} severity={snackbar.severity} message={snackbar.message}/>
                <DialogComponent open={openedDialog} title={t('account:almost_finished')} content={t('account:sent_message_to_email_account')} buttonLabel={t('account:ok')} click={() => setDialogOpen(false)}/>
                <Loading loading={loading} />
            </div>
}

export default withOrientationChange(AccountDetailsCard);