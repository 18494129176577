import React, { MouseEvent } from "react";
import styles from './Dialog.module.scss'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

interface IDialog {
    open: boolean,
    title: string,
    content: string,
    buttonLabel: string,
    click: (event: MouseEvent) => void,
}

const DialogComponent = (props: IDialog) => {
    return (
        <Dialog open={props.open} className={styles.dialog_wrapper}>
            <DialogTitle className={styles.dialog_title}>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText className={styles.dialog_content}>{props.content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.click} className={styles.dialog_confirmation_button} autoFocus>{props.buttonLabel}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogComponent;